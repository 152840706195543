import Vue from 'vue';
import Vuex from 'vuex';
import { bus } from '@/main';
import menu from './modules/menu';
import chat from './modules/chat';
import todo from './modules/todo';
import survey from './modules/survey';
import auth from './modules/auth';
import project from './modules/project';
import plans from './modules/plans';
import bot from './modules/bot';
import resources from './modules/resources';
import users from './modules/users';
import finance from './modules/finance';
import referrers from './modules/referrers';
import modals from './modules/modals';
import merchantPartner from './modules/merchantPartner';
import comebackers from './modules/comebackers';

Vue.use(Vuex);

export default new Vuex.Store({
    mutations: {
        changeLang(state, payload) {
            bus.$i18n.locale = payload;
            localStorage.setItem('currentLanguage', payload);
        },
    },
    actions: {
        setLang({ commit }, payload) {
            commit('changeLang', payload);
        },
    },
    modules: {
        menu,
        chat,
        todo,
        survey,
        modals,
        referrers,
        auth,
        project,
        plans,
        bot,
        resources,
        users,
        finance,
        merchantPartner,
        comebackers,
    },
});
