<template lang="pug">
	b-form-group.mb-1.rules-confirm(:class="{'has-error': hasError,'show-rules': show}")
		.position-relative(:class="{'show-rules': show}")
			b-form-checkbox.d-inline-flex.align-items-center(v-model="isChecked" :value='true')
				div
					span {{ $t('rulesGroup[0]') }}
					| &nbsp;
					span.ml-1.cursor-pointer.text-primary(v-if="isMobile" @click="show=!show") {{ $t('forms.rulesAgrementToggle') }}
					span.ml-1.cursor-pointer.text-primary(v-if="!isMobile" @click="show=!show") {{ $t('forms.rulesAgrementToggle') }}
			span.rules-confirm__arrow(@click="show=!show")
		.rules-confirm__rules(:class="{'show-rules': show}")
			b-form-checkbox-group.d-flex.flex-column(v-model="rulesValue" :state="!hasError")
				b-form-checkbox(v-for="(item, i) in optionsRules" :key="i" :value="item.value")
					a.rules-confirm__checkbox-link(v-if="useExternalLink" :href="mainDomain+item.link?.name" target="_blank") {{ item.text }}
					router-link.rules-confirm__checkbox-link(v-else :to="item.link" target="_blank") {{ item.text }}
		b-form-invalid-feedback.text-error.b3.m-0(:state="!hasError") {{ $t('rulesGroupError') }}
</template>

<script>
import { DOMAIN_PROD } from '@/api';

export default {
    name: 'RulesConfirm',
    props: {
        isPlanForever: {
            type: Boolean,
            default: false,
        },
        showOnlyRuDocs: {
            default: false,
            type: Boolean,
        },
        hadCheckedOnce: {
            default: false,
            type: Boolean,
        },
        useExternalLink: {
            default: false,
            type: Boolean,
        },
    },
    data: () => ({
        show: false,
        rulesValue: [],
        hadChecked: false,
        hasError: false,
    }),
    mounted() {
        this.hadChecked = this.hadCheckedOnce;
        this.hasError = this.hadChecked && !this.isChecked;
    },
    computed: {
        isChecked: {
            get() {
                return this.rulesValue.length == this.optionsRules.length;
            },
            set(v) {
                if (this.isChecked && v == false) {
                    this.rulesValue = [];
                    return;
                }

                this.hadChecked = true;
                this.rulesValue = v ? _.map(this.optionsRules, or => or.value) : this.rulesValue;
            },
        },
        stateRules() {
            return this.rulesValue.length === this.optionsRules.length && this.isChecked;
        },
        optionsRules() {
            let ru = !this.showOnlyRuDocs ? 'ru_' : '';

            if (this.showOnlyRuDocs) {
                return [
                    { text: this.$t('h1.serviceRules'), value: 2, link: { name: `${ru}terms` } },
                    { text: this.$t('h1.servicePrivacy'), value: 3, link: { name: `${ru}privacy` } },
                ];
            }

            return _.filter(
                [
                    { text: this.$t('h1.serviceRules'), value: 2, link: { name: `${ru}terms` } },
                    { text: this.$t('h1.servicePrivacy'), value: 3, link: { name: `${ru}privacy` } },
                    { text: this.$t('h1.serviceAd'), value: 4, link: { name: `${ru}ad_accept` } },
                    { text: this.$t('h1.servicePersonalData'), value: 5, link: { name: `${ru}privacy_accept` } },
                    !this.isPlanForever
                        ? { text: this.$t('h1.serviceAgree'), value: 6, link: { name: `${ru}sub_terms` } }
                        : null,
                    { text: this.$t('h1.agencyContract2'), value: 7, link: { name: `${ru}agency_contract` } },
                    { text: this.$t('h1.paymentProcessing'), value: 8, link: { name: `${ru}payment_processing` } },
                    { text: this.$t('tech.title'), value: 9, link: { name: `${ru}tech` } },
                ],
                el => !_.isNull(el)
            );
        },
        mainDomain() {
            return `${window.location.protocol}//${DOMAIN_PROD}/`;
        },
    },
    watch: {
        isChecked(n, o) {
            this.$emit('isChecked', n);
        },
        rulesValue(n) {
            this.$emit('isChecked', this.stateRules);
        },
        hadCheckedOnce(nV) {
            this.hadChecked = nV;
            this.hasError = this.hadChecked && !this.isChecked;
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.rules-confirm {
    &__arrow {
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 0;
        width: 22px;
        height: 22px;
        background: url('/assets/img/icons/arrow-down.svg');
        background-size: cover;
        transform: rotate(180deg);
        transition: 0.1s linear;
    }

    a.rules-confirm__checkbox-link {
        &:hover,
        &:active {
            color: var(--brand-primary);
            text-decoration: underline;
        }
    }

    &__rules {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s cubic-bezier(0.9, 0, 0.8, 0.2);
        padding-left: 34px;

        &.show-rules {
            max-height: 1000px;
            opacity: 1;
            transition: max-height 0.2s cubic-bezier(0.9, 0, 0.8, 0.2);
        }
    }

    .invalid-feedback {
        transition: all 0.3s ease;
    }
    &.show-rules {
        .invalid-feedback {
            padding-left: 34px;
        }
        .rules-confirm__arrow {
            transform: rotate(0);
            top: 2px;
        }
    }

    .custom-checkbox {
        margin-bottom: 15px;
    }
    .custom-control {
        padding-left: 0;
    }

    .custom-control-label {
        display: inline-flex !important;
        gap: 11px;
        padding-left: 0;
        padding-right: 20px;
        line-height: 1.57 !important;

        &::before {
            border-color: var(--brand-primary) !important;
            position: initial !important;
            background-position: center;
            background-size: 53%;
            background-repeat: no-repeat;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: var(--brand-success);
        border-color: var(--brand-success) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    body.dark & .custom-control-input:checked ~ .custom-control-label::before {
        background-color: var(--primary-text-color) !important;
        border-color: var(--primary-text-color) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2327282A' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &.has-error {
        .custom-control-label {
            &::before {
                border-color: var(--brand-error) !important;
            }
        }
    }
}
</style>
