<template lang="pug">
	.page.page--graphics
		.d-flex.filter.mb-4.justify-content-end(v-if="date && me && masks && enablePicker")
			.d-flex.position-relative.date-picker-control.date-picker-control--small
				v-date-picker.w-100.date-picker.date-picker--small(@input='changeDate' v-model="date" :masks="masks" is-range  :popover="{ visibility: 'click' }" :min-date="new Date(me.registeredAt)" :max-date="new Date()")
					template(v-slot="{ inputValue, inputEvents, togglePopover }")
						span.icon-calendar(@click="togglePopover()")
						.d-flex.date-picker__input(@click="togglePopover()" v-if="date?.start && date?.end")
							input.b3.mr-1.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !date.start && !date.end}" id="creation-date-calendar" :value="date.start | dateDDMMMYYDots" v-on="inputEvents.start" readonly)
							span.b3.mr-1(v-if="date.start && date.end") -
							span.b3.date-picker__input-calendar(v-if="date.start && date.end") {{ date.end | dateDDMMMYYDots }}
							input.visually-hidden(:value="date.end | dateDDMMMYYDots" v-on="inputEvents.end")
			dropdown-general.ml-2(:list="dropdownList" @onChange='changeGroup' selecteDefault="0")

		.graphics(v-if="!isLoading")
			.line.whiteCard.foreground-color
				.conversion(v-if="stats?.conversion")
					span.b3.text-grey.mr-10 {{ $t('project.conversion') }}
					span.percent {{stats.conversion}}%
					question.ml-2(:id="!isMobile ? `tooltip-conversion` : ''" @click="isMobile ? $bvModal.show(`tooltip-conversion`) : ''" color="grey")
					modal-tooltip(
						:id="`tooltip-conversion`"
						:text="`${$t('tutorials.conversionDesc1')}`"
						:textMobile="`${$t('tutorials.conversionDesc1')}`"
					)

				.charts
					Chart(height="270"  type="area" :data="chartViews" :labelVertical="labelsVertical" v-model="labelsVerticalArray[0]")
					Chart(height="270"  type="area" :data="chartActiveRenew" :labelVertical="labelsVertical" v-model="labelsVerticalArray[1]")
					template(v-if="isMobile")
						Chart(height="270"  type="area" :data="chartIncome" :labelVertical="labelsVertical" v-model="labelsVerticalArray[2]")
						Chart(height="270"  type="area" :data="chartCanceledRefound" :labelVertical="labelsVertical" v-model="labelsVerticalArray[3]")


			.line.whiteCard.foreground-color(v-if="!isMobile")
				.charts
					Chart(height="270"  type="area" :data="chartIncome" :labelVertical="labelsVertical" v-model="labelsVerticalArray[2]")
					Chart(height="270"  type="area" :data="chartCanceledRefound" :labelVertical="labelsVertical" v-model="labelsVerticalArray[3]")
		.loading(v-else)
</template>
<script>
import question from '@/components/Icons/question.vue';
import Chart from '@/components/Charts/Chart.vue';
import { mapActions, mapGetters } from 'vuex';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral.vue';
import { STORAGE } from '@/api/storage';
import ModalTooltip from '@/components/Modals/ModalTooltip.vue';

export default {
    components: {
        ModalTooltip,
        DropdownGeneral,
        question,
        Chart,
    },
    data: () => ({
        date: null,
        period: null,
        labelsVerticalArray: [false, false, false, false],
        masks: {
            input: 'DD.MM.YYYY',
            weekdays: 'WW',
        },
        enablePicker: null,
        currentLanguage: STORAGE.getLang(),
    }),
    created() {
        this.changeGroup('daily');
    },
    mounted() {
        this.enablePicker = 1;
    },
    props: ['isLoading'],
    computed: {
        ...mapGetters({
            stats: 'merchantPartner/statistics',
            me: 'auth/me',
        }),
        labelsVertical() {
            return this.labelsVerticalArray.includes(true);
        },
        chartViews() {
            return [
                {
                    title: this.$t('project.views'),
                    color: '#B971F3',
                    period: this.stats?.period,
                    data: this.stats?.graph.subscriptionsViews ?? [],
                },
            ];
        },
        chartActiveRenew() {
            return [
                {
                    title: this.$t('knowledgeBase.subscriptions'),
                    color: '#2A905F',
                    period: this.stats?.period,
                    data: this.stats?.graph.subscriptionsActive ?? [],
                },
                {
                    title: this.$t('h1.extends'),
                    color: '#06743F',
                    period: this.stats?.period,
                    data: this.stats?.graph.subscriptionsRenew ?? [],
                },
            ];
        },
        chartIncome() {
            return [
                {
                    title: this.$t('project.profitRu'),
                    color: '#108A4F',
                    period: this.stats?.period,
                    data: this.stats?.graph.incomeOther ?? [],
                },
                {
                    title: this.$t('project.profitWorld'),
                    color: '#06743F',
                    period: this.stats?.period,
                    data: this.stats?.graph.incomeWorld2ru ?? [],
                },
            ];
        },
        chartCanceledRefound() {
            return [
                {
                    title: this.$t('h1.unsubscribes'),
                    color: '#EAEAEA',
                    period: this.stats?.period,
                    data: this.stats?.graph.subscriptionsCanceled ?? [],
                },
                {
                    title: this.$t('h1.returns'),
                    color: '#FF7C7C',
                    period: this.stats?.period,
                    data: this.stats?.graph.subscriptionsRefound ?? [],
                },
            ];
        },
        dropdownList() {
            return [
                {
                    id: 'daily',
                    title: this.$t('project.dropdownStatistics[0]'),
                },
                {
                    id: 'weekly',
                    title: this.$t('project.dropdownStatistics[1]'),
                },
                {
                    id: 'monthly',
                    title: this.$t('project.dropdownStatistics[2]'),
                },
            ];
        },
    },
    methods: {
        getActivity() {
            if (!this.date) return;
            this.$emit('changeFilter', { ...this.date, group: this.period });
        },
        changeDate(v) {
            this.getActivity();
        },
        changeGroup(i) {
            this.period = i;
            if (!this.date) {
                switch (this.period) {
                    case 'daily':
                        this.date = {
                            start: new Date(new Date().getTime() - 86400000 * 6),
                            end: new Date(),
                        };
                        break;
                    case 'weekly':
                        this.date = {
                            start: new Date(new Date().getTime() - 86400000 * 6 * 3),
                            end: new Date(),
                        };
                        break;
                    case 'monthly':
                        this.date = {
                            start: new Date(new Date().getTime() - 86400000 * 6 * 3 * 4),
                            end: new Date(),
                        };
                        break;
                }
            }
            // const reg=new Date(this.me.registeredAt)
            // if(this.date.start < reg){
            //     this.date.start=reg
            // }

            this.getActivity();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';
.whiteCard {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 1.5rem;
}
.page--graphics {
    min-height: 53vh;
    position: relative;
    .loading {
        position: absolute;
    }
}
.conversion {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .percent {
        font-size: 24px;
    }
}
.graphics {
    display: flex;
    flex-direction: column;

    .line {
        background: #000;

        .charts {
            gap: 20px;
            display: flex;
            @include respond-below(sm) {
                flex-direction: column;
            }
            & > * {
                flex: 1;
                max-width: 50%;
                @include respond-below(sm) {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
