<template lang="pug">
	modal-general(:id="'income-detail'" :title="$t('merchantPartners.catalogItem.projectSubscription')" :contentClass="'content-full modal-plans-income'")
		.blue-line(v-if="conversion!==undefined")
			.title
				span(v-html="$t('merchantPartners.catalogItem.averageConversion')")
			.value {{conversion}}%
		.table-income
			.head.line(v-if="!isMobile")
				.cell.title(v-html="$t('partners.incomeTariffs.tariff')" )
				.cell.title(v-html="$t('partners.incomeTariffs.incomeFirst')" )
				.cell.title(v-html="$t('partners.incomeTariffs.averageTotal')" )
				.cell.title(v-html="$t('partners.incomeTariffs.percent')" )
			.line(v-for="item in plansFormatted")
				.cell
					b(v-html="item.title")
					span(v-html="itemToInterval(item)")
				.cell
					.title(v-if="isMobile" v-html="$t('partners.incomeTariffs.incomeFirst')" )
					.value(v-html="moneyFormatFirst(item.price)")
				.cell
					.title(v-if="isMobile" v-html="$t('partners.incomeTariffs.averageTotal')" )
					.value(v-html="moneyFormatAverage(item)")
				.cell
					.title(v-if="isMobile" v-html="$t('partners.incomeTariffs.percent')" )
					.value {{item.percentOfUse }} %

</template>

<script>
import ModalGeneral from '@/components/Modals/ModalGeneral.vue';
import Question from '@/components/Icons/question.vue';
import ModalTooltip from '@/components/Modals/ModalTooltip.vue';
import { Money } from '@/models/money';
import _ from 'lodash';
import { PERIODS } from '@/models/plan';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ModalPlansIncome',
    components: {
        ModalTooltip,
        Question,
        ModalGeneral,
    },
    created() {
        this.initProjectPlansStatistics({ id: this.averageIncome.project.id });
    },
    props: ['conversion', 'averageIncome'],
    methods: {
        ...mapActions({
            initProjectPlansStatistics: 'project/getProjectPlansStatistics',
        }),
        itemToInterval(_item) {
            const item = _.cloneDeep(_item);
            let money = item.price;
            if (!money.priceWithCurrency) {
                money = new Money(money);
            }
            let ret = money.priceWithCurrency();

            ret += ` ${this.$t('plan.periods.' + (item.period?.title ?? 'forever'))}`;
            return ret;
        },
        moneyFormatFirst(_money) {
            let money = _.cloneDeep(_money);
            if (!money.priceWithCurrency) {
                money = new Money(money);
            }
            const rate = this.averageIncome?.project.rateNew ?? this.averageIncome?.project.rate;
            money.amount *= rate / 100;
            return money.priceWithCurrency();
        },

        moneyFormatAverage(plan) {
            let price = plan.price;
            if (!price.priceWithCurrency) {
                price = new Money(price);
            }
            const money = new Money({ amount: price.amount * 100, currency: price.currency });
            const rate = this.averageIncome?.project.rateNew ?? this.averageIncome?.project.rate;
            money.amount *= rate / 100;
            if (plan.averageCountOfUse > 0) {
                money.amount *= plan.averageCountOfUse;
            }
            return money.priceWithCurrency();
        },
    },
    computed: {
        ...mapGetters({
            plansStatistics: 'project/plansStatistics',
        }),
        plansFormatted() {
            return this.averageIncome?.project.plans
                .filter(itm => {
                    return itm.status === 'ACTIVE';
                })
                .map(itm => {
                    if (this.plansStatistics) {
                        const item = this.plansStatistics?.find(item => item.id.toString() === itm.id.toString());

                        itm.averageCountOfUse = item?.averageCountOfUse;
                        itm.percentOfUse = item?.percentOfUse;
                    }
                    return itm;
                });
        },
    },
};
</script>
<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

#income-detail {
    .modal-general__content,
    .modal-dialog {
        max-width: 616px;

        @include respond-below(sm) {
            max-width: 100%;
        }
    }
    .modal-header {
        .h3 {
            font-weight: 600 !important;
        }
    }

    .blue-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--primary-light-color);
        border-radius: 8px;
        padding: 10px 20px;
        margin-bottom: 20px;

        .value {
            font-size: 18px;
            font-weight: 600;
            color: var(--primary-text-color);
        }
    }

    .table-income {
        display: table;

        .head {
            color: var(--grey-color);
        }

        .line {
            display: flex;
            gap: 20px;

            &:not(.head) .cell {
                align-content: center;
                padding-top: 10px;
            }
        }

        .title {
            color: var(--grey-text-color);
            font-size: 14px;
            line-height: 1.14;
        }

        .cell {
            flex: 1;

            &:first-child {
                border-right: 1px solid var(--border-dropdown-color);
                padding-right: 10px;
                width: 300px;
                flex: 1.5;
            }

            &:not(:first-child) {
                text-align: center;
                width: 20%;
            }

            .value {
                border-radius: 8px;
                background: var(--grey-bg-color);
                padding: 4px 0px;
                margin: 0 10px;
            }

            b {
                color: var(--main-text-color);
                font-weight: 600;
                display: block;
                margin-bottom: 5px;
            }

            span {
                color: var(--grey-color);
            }
        }

        @include respond-below(sm) {
            .line {
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                border: 1px solid var(--border-dropdown-color);
                padding: 15px 17px;
                margin-bottom: 20px;
            }
            .cell {
                display: flex;
                justify-content: space-between;
                width: 100% !important;
                border-right: none !important;
                padding: 5px 0px 15px 0 !important;
                border-bottom: 1px solid var(--border-dropdown-color);

                .value {
                    margin: 0 !important;
                }

                &:first-child {
                    padding-bottom: 10px !important;
                    flex-direction: column;
                }
            }
        }
    }
}
</style>
