<template lang="pug">
	b-dropdown.dropdown.b3.dropdown-general.animated(v-if="list" :text='selected' :class="[size, {'not-chosen' : notChosen && selected == list[0].title}, icon]" menu-class='p-0 m-0 b3' :disabled='disabled')
		template(v-slot:button-content)
			.direction(@click.stop.prevent="changeDirection" :class="direction")
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_31498_35814)"><path d="M8.83915 15.6092C8.44485 16.2163 7.55619 16.2163 7.1619 15.6092L4.26145 11.1437C3.82934 10.4784 4.30679 9.599 5.10008 9.599H10.901C11.6943 9.599 12.1717 10.4784 11.7396 11.1437L8.83915 15.6092Z" fill="#CBD2D8"/><path d="M7.16085 1.39076C7.55515 0.783701 8.44381 0.783701 8.8381 1.39076L11.7386 5.85629C12.1707 6.52156 11.6932 7.401 10.8999 7.401H5.09903C4.30575 7.401 3.8283 6.52156 4.2604 5.85629L7.16085 1.39076Z" fill="#8292A1"/></g><defs><clipPath id="clip0_31498_35814"><rect width="16" height="16" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>
			.selected(v-html="selected")
		b-dropdown-item(v-for='(item, i) in list' :key='i' @click="selected = String(item.title), $emit('onChange', notChosen ? selected : (fullItem ? item : item.id))" )
			span(v-if="item.title" v-html="item.title")
			svg.ml-3(v-if="selected == item.title" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M2.29883 9.24023L5.68129 12.9609L14.7012 3.03906" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
</template>

<script>
export default {
    name: 'DropdownGeneral',
    props: {
        list: {
            default: () => [],
            type: Array,
        },
        size: {
            default: '',
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        notChosen: {
            default: false,
            type: Boolean,
        },
        fullItem: {
            default: false,
            type: Boolean,
        },
        selecteDefault: {
            default: 0,
            type: [Number, String],
        },
        icon: {
            default: '',
            type: String,
        },
    },
    data: () => ({
        directionList: ['asc', 'desc'],
        direction: 'asc',
        selected: '',
    }),
    created() {
        this.defaultSelected();
    },
    methods: {
        defaultSelected() {
            this.selected = this.list[this.selecteDefault].title;
        },
        changeDirection() {
            this.direction = this.directionList.find(itm => itm !== this.direction);
            this.$emit('changeDirection', this.direction);
        },
    },
    watch: {
        selecteDefault(v) {
            this.defaultSelected();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.dropdown-general {
    height: 38px;

    &:hover,
    &:active,
    &:focus {
        button {
            border: 1px solid var(--button-border-color) !important;
            background-color: var(--foreground-color) !important;
            color: var(--main-text-color) !important;
        }
    }

    .selected {
        border-left: 1px solid var(--border-dropdown-color);
        padding-left: 10px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .direction {
        display: flex;
        align-items: center;
        transition: all 0.3s ease;

        margin: -15px;
        padding: 15px;
        &.desc {
            transform: scale(1, -1);
        }
    }
    button {
        height: 38px;
        padding-left: 15px !important;
        padding-right: 15px !important;
        border: 1px solid var(--border-line) !important;
        background-color: var(--foreground-color) !important;
        border-radius: var(--border-radius-rounded) !important;
        color: var(--main-text-color) !important;
        box-shadow: none !important;
    }

    &.icon-calendar {
        button {
            position: relative;
            padding-left: 49px !important;

            &::before {
                z-index: 2;
                position: absolute;
                content: '';
                top: 50%;
                left: 15px;
                width: 24px;
                height: 24px;
                mask-image: url('/assets/img/icons/calendar.svg');
                background-color: var(--primary-text-color);
                mask-size: cover;
                mask-repeat: no-repeat;
                transform: translateY(-50%);
                cursor: pointer;
                pointer-events: none;
            }
        }
    }

    .show {
        box-shadow: none !important;
    }

    .dropdown-toggle {
        justify-content: space-between;

        &::after {
            width: 18px;
            min-width: 18px;
            height: 18px;
            background: url('/assets/img/icons/arrow.svg');
            margin-left: 10px !important;
            border: none !important;
        }
    }

    &.show .dropdown-toggle {
        border: 1px solid var(--button-border-color) !important;
        border-bottom-left-radius: var(--border-radius-rounded) !important;
        border-bottom-right-radius: var(--border-radius-rounded) !important;
        background-color: var(--foreground-color) !important;
        color: var(--main-text-color) !important;

        &::after {
            transform: rotate(180deg);
        }
    }

    .dropdown-menu {
        min-width: 128px;
        top: 10px !important;
        right: auto !important;
        width: max-content;
        background-color: var(--foreground-color) !important;
        border-radius: var(--border-radius-rounded) !important;
        box-shadow: var(--box-shadow) !important;

        /* Скрываем scrollbar для IE, Edge и Firefox */
        -ms-overflow-style: none; /* IE и Edge */
        scrollbar-width: none; /* Firefox */

        /* Скрываем scrollbar для Chrome, Safari и Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        @include respond-below(md) {
            width: 100%;
        }
    }

    &.show ul li:first-child:before {
        content: none !important;
    }

    .dropdown-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px !important;
        color: var(--main-text-color) !important;
        transition: var(--animation-time-short);

        &::after {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 0;
            height: 0.5px;
            background-color: var(--border-line);
        }

        &:hover,
        &:active,
        &:focus {
            background-color: var(--grayscale-bg-dark-back);
            color: var(--main-text-color) !important;
        }
    }

    &.dropdown ul li:last-child {
        .dropdown-item {
            &::after {
                content: none;
            }
        }
    }

    &.large {
        height: 50px;

        button {
            height: 50px;
        }

        .dropdown-menu {
            top: 25px !important;
        }
    }

    &.small {
        height: 36px;

        button {
            padding: 6px 15px;
            height: 36px;
        }

        .dropdown-menu {
            top: 10px !important;
        }
    }

    &.animated {
        .dropdown-menu {
            overflow: auto;
            opacity: 0;
            display: block !important;
            max-height: 0 !important;
            transition: max-height var(--animation-time-short) ease-in-out;
            border: 1px solid transparent !important;
            box-shadow: var(--box-shadow-hover) !important;

            &:not(.show) {
                padding: 0;
                border: none;
            }

            &.show {
                opacity: 1;
                transition: max-height var(--animation-time-short) ease-in-out;
                border: 1px solid var(--border-line) !important;
                max-height: 360px !important;
            }
        }
    }

    &.not-chosen {
        button {
            color: var(--grayscale-color-grey) !important;

            &:hover,
            &:active,
            &:focus {
                button {
                    color: var(--grayscale-color-grey) !important;
                }
            }
        }

        &.show .dropdown-toggle {
            color: var(--grayscale-color-grey) !important;
        }

        .dropdown-menu {
            li:first-child {
                display: none;
            }
        }
    }
}
</style>
