<template lang="pug">

	extends ./table.pug

	block tableTH
		template(v-slot:cell(createdAt)='data')
			p.mb-2.b3(v-if="data.item.id") {{ data.item.id }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item.createdAt") {{ data.item.createdAt | dateDDMMMYYYY }}

		template(v-slot:cell(account)='data')
			p.mb-2.text-nowrap(v-if='data.item.isWorld2ru() && data.item.isUSDTWithdrawal()') {{ data.item.price.price() }} {{ data.item.price.currencyTitleUSDT() }}
			p.mb-2.text-nowrap(v-else v-html="data.item.price.priceWithCurrency()")
			p.mb-0.text-sub.text-nowrap {{ data.item.account.id }}

		template(v-slot:cell(method)='data')
			p.mb-2.b3(v-if="data.item.purse") {{ data.item.purse }}
			p.mb-0.b4.text-grey(v-if="data.item.method") {{ data.item.method }}

		template(v-slot:cell(status)='data')
			p.b3.mb-0.vuetable__status(v-if='data.item' :class="statusVariant(data.item)" :id="!isMobile ? `status-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`status-${data.item.id}`) : ''")
				span.b3(v-if='data.item.statusO') {{ data.item.statusO[`title${locale.toUpperCase()}`] }}
				span.b3(v-else) {{ data.item.status }}

			modal-tooltip(
				v-if='data.item.errorMessage != null'
				:id="`status-${data.item.id}`"
				:text="data.item.errorMessage"
				:textMobile="data.item.errorMessage"
			)

		template(v-slot:cell(files)='data')
			template(v-if="me.isMerchantPartner")
				i.fontello-btn.fontello-btn-small.fontello-btn-primary.fontello-icon-message2(v-if='data.item.comment' :id="!isMobile ? `comment${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`comment${data.item.id}`) : ''")

					b-tooltip(v-if="!isMobile" :target="`comment${data.item.id}`" no-fade placement="left" triggers="hover")
						comment-tooltip(:item="data.item")

					b-modal(ref="modal" v-if="isMobile" :id="`comment${data.item.id}`" content-class="modal-indents" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
						template(#modal-header="{ close }")
							h3.h3(v-html="$t('h1.comment')")
							icon-close(@click="close()")
						comment-tooltip(:item="data.item")
						.btn.btn-primary.mt-4(@click="closeModal()") {{ $t('social.good') }}

			template(v-else)
				i.fontello-btn.fontello-btn-small.fontello-btn-primary.fontello-icon-download.mr-2(v-if='data.item.isOrganization()' :title='$t("finance.withdrawal.showReports")' @click="$bvModal.show(`reports-${data.item.id}`)")
				i.fontello-btn.fontello-btn-small.fontello-btn-primary.fontello-icon-download.mr-2(v-if='data.item.isTinkoff() && data.item.hasPayments() && !data.item.isError()' @click='download(data.item.id)' :title='$t("finance.withdrawal.downloadReports")')
				i.fontello-btn.fontello-btn-small.fontello-btn-primary.fontello-icon-message2(v-if='data.item.comment' :id="!isMobile ? `comment${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`comment${data.item.id}`) : ''")

					b-tooltip(v-if="!isMobile" :target="`comment${data.item.id}`" no-fade placement="left" triggers="hover")
						comment-tooltip(:item="data.item")

					b-modal(ref="modal" v-if="isMobile" :id="`comment${data.item.id}`" content-class="modal-indents" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
						template(#modal-header="{ close }")
							h3.h3(v-html="$t('h1.comment')")
							icon-close(@click="close()")
						comment-tooltip(:item="data.item")
						.btn.btn-primary.mt-4(@click="closeModal()") {{ $t('social.good') }}

				modal-general(:id="`reports-${data.item.id}`" :title="isMobile ? $t('h1.finance') : $t('finance.withdrawal.reportsTitle')")
					reports
</template>

<script>
import { bus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TablePagination from './TablePagination';
import TableShowMore from './TableShowMore';
import { locale } from '@/main';
import { STATUS } from '@/models/withdraw';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import CommentTooltip from '@/components/Common/CommentTooltip';
import IconClose from '@/components/Common/IconClose';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import Reports from '@/components/Modals/Reports';

export default {
    name: 'FinanceWithdraw',
    mixins: [TableMixin],
    components: {
        TableSearch,
        TablePagination,
        TableShowMore,
        ModalTooltip,
        IconClose,
        CommentTooltip,
        ModalGeneral,
        Reports,
    },
    props: {
        role: {
            type: String,
            default: 'item',
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            sortBy: 'createdAt',
            searchKeys: ['createdAt'],
            noLocalSorting: true,
            sorting: ctx => {
                let data = {};

                data[`order[${ctx.sortBy}]`] = ctx.sortDesc ? 'desc' : 'asc';

                this.isbusy = true;
                this.getItems(data);
            },
            fields: [],
            items: [],
            emptyText: bus.$t('project.noWithdrawal'),
            locale: locale,
        };
    },
    mounted() {
        this.getFields();

        this.getItems = _.debounce((data = {}) => {
            let f = this.parseFilters();

            if (f.find) {
                let s = _.find(
                    STATUS,
                    st =>
                        st.titleRU.toLowerCase() == f.find.toLowerCase() ||
                        st.titleEN.toLowerCase() == f.find.toLowerCase()
                );
                if (s && s.id) f.find = s.id;

                if (!_.isNaN(Number(f.find)) && Number(f.find) % 1 !== 0) {
                    f.find = Math.round(f.find * 100);
                }
            }

            this.get(_.extend(f, { page: this.currentPage, itemsPerPage: this.perPage }, data))
                .then(v => {
                    if (this.currentPage == 1) this.items = [];
                    this.items = this.items.concat(v);
                    this.$emit('onGetItems', { amount: this.items.length, type: 'withdrawals' });
                    this.isbusy = false;
                })
                .catch(v => (this.isbusy = false));
        }, 500);
        bus.$on('tableFinanceExport', this.exportCSV);
        bus.$on('tableFinanceWithdrawalRefresh', query => {
            this.searching(query);
        });

        this.searching();
    },
    created() {
        this.searching = _.debounce(query => {
            this.sorting(this);
        }, 400);
    },
    destroyed() {
        bus.$off('tableFinanceWithdrawalRefresh');
        bus.$off('tableFinanceExport');
        this.$store.commit('finance/setWithdrawalsTotal', 0);
    },
    computed: {
        ...mapGetters({
            totalItems: 'finance/withdrawalsTotal',
        }),
    },
    methods: {
        ...mapActions({
            get: 'finance/withdrawals',
            csv: 'finance/exportWithdrawals',
            csvWithdrawals: 'finance/withdrawalsCSV',
        }),
        localSorting(ctx) {},
        statusVariant(item) {
            let cp = item.errorMessage == null ? '' : 'cursor-pointer ';
            if (item.updatedAt != item.createdAt && (item.isSuccess() || item.isError())) cp += '';

            if (item.isSuccess()) return cp + 'text-success';
            if (item.isRequested() || item.isChecked()) return cp + 'text-orange';
            if (item.isRefund()) return cp + 'text-orange';
            if (item.isPaid()) return cp + 'text-success';
            if (item.isError()) return cp + 'text-error';

            return cp;
        },
        download(id) {
            this.csv({ id: id });
        },
        exportCSV() {
            this.csvWithdrawals(this.parseFilters());
        },
        closeModal() {
            this.$refs.modal.hide();
        },
        getFields() {
            this.fields = [
                {
                    key: 'createdAt',
                    label: this.$t('finance.withdrawalTitle'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'method',
                    label: this.$t('h1.props') + ' / ' + this.$t('h1.method'),
                    tdClass: 'align-middle',
                    thClass: 'text-nowrap',
                },
                {
                    key: 'account',
                    label: this.$t('forms.sum') + ' / ' + this.$t('finance.bankAccount'),
                    tdClass: 'align-middle',
                    thClass: 'text-nowrap',
                },
                {
                    key: 'status',
                    label: this.$t('project.status'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'files',
                    label: this.$t('h1.additionally'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
            ];
        },
    },
};
</script>
