<template lang="pug">
	.block
		.top
			.left
				my-input-search.mr-2.followers__search(
					v-model="search"
					:placeholder="$t('dashboards.search')"
				)
				//pw-btn-icon.mr-2(:used="usedFilters" :icon="'fontello-icon-setting-4'"  @click="show" :class="{used:filterUsed}")
			.right
				//dropdown-sort.dropdown(:list="dropdownListSort"  @onChange='changeSort' @changeDirection="changeDirection")
				table-per-page.ml-auto(v-if="total" @onChange="changePageSize" :from="from" :total="total")

		template(v-if="!isLoading")
			.list(v-if="items?.length ")
				.item(v-for="item in items" )
					.data
						.info
							router-link(:to="{name: 'project_id', params: {id: item.id}}" )
								img(v-if='item.project.image' :src='item.project.image.contentUrl' :alt='item.project.title')
							.text
								router-link(:to="{name: 'project_id', params: {id: item.id}}" )
									.title {{ item.project.title }}


								b-badge.mb-2.mt-1.bage-danger(v-if="!item.project.isActive()" pill variant='danger' v-html="$t('merchantPartners.alerts.unavailable')")

								.description {{ formatDescription(item.project.description) }}
						.btns
							router-link.btn.primary-light(:to="{name: 'project_id', params: {id: item.id}}" v-html="$t('merchantPartners.catalog.go')" )

							.deleteButton(v-if="!isArchived" @click="itemRemoveProject=item")
								BasketIcon(v-if="item.canBeDeleted")
								BasketArchive(v-else )
							.btn.btn-success-light(v-else @click="unblock(item)" v-html="$t('merchantPartners.catalog.reset')" )

					.stat
						.main
							.title(v-html="$t('merchantPartners.catalog.main')" )
							.line
								.label(v-html="$t('merchantPartners.catalog.rate')" )
								.value {{item.rate}}%
							.line(v-if="changeRateBadge(item)")
								b-badge.mt-1.bage( variant='orange' v-html="$t('merchantPartners.alerts.changeRate',changeRateBadge(item))")

							.line
								.label(v-html="$t('merchantPartners.catalog.conversion')" )
								.value {{ item.project.conversion }}%
						//.divider
						//.average
							.title(v-html="$t('merchantPartners.catalog.averageIncome')" )
							.line
								.label(v-html="$t('merchantPartners.catalog.firstIncome')" )
								.value(v-html="incomeFormat(item,'first')")
							.line
								.label(v-html="$t('merchantPartners.catalog.allTime')" )
								.value(v-html="incomeFormat(item,'total')")
			.empty.text-center(v-else)
				h1.mb-3.text-center 😢
				span.b3.text-center.text-grey {{ isArchived?$t('project.emptyProjects'):$t('project.emptyProjectsArchive') }}

		PageLoader(v-else)


		modal-general#modalFilters(:contentClass="'content-full catalog-filter'" ref='modalFilters' )
			.modal-general-header-disable
			template(v-for="(item,index) in filterList")
				.modalBlock(v-if="item.bigTitle")
					.bigTitle(v-if="item.bigTitle" v-html="item.bigTitle")
				.modalBlock
					.clear(
						v-if="isShowClear(index)"
						@click="clear(index)"
						:class="{clearFirst:index===4}"
						) {{$t('merchantPartners.catalog.clear')}}
					.title(v-if="item.title" v-html="item.title")
					.value
						MyInput(:label="$t('date.from')" v-model="filters[item.code].from" type="number")
						MyInput(:label="$t('forms.until')" v-model="filters[item.code].to" type="number")


			.d-flex.align-items-center.flex-gap-05.mt-4
				.btn.btn-primary.flex-grow-1(@click="submitFilter" v-html="$t('paymentPage.apply')")
				.btn.flex-grow-1(@click="resetFilter" v-html="$t('button.resetAll')")

		b-modal#cannotBeDeleted( ref='modal' centered content-class="remove-project" header-class="p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close @hide="canNotBeDeletedClose")
			template(#modal-header="{ close }")
				span.b1( v-html="$t('merchantPartners.cannotBeDeleted.title')")
				icon-close(@click="close")
			.body
				.text.mb-4( v-html="$t('merchantPartners.cannotBeDeleted.description')")
				.text-grey.mb-4( v-html="$t('merchantPartners.cannotBeDeleted.description2')")
				.btn.btn-primary.height38(@click="$bvModal.hide('cannotBeDeleted')" v-html="$t('resources.btnFine')" )

		b-modal#modalRemove( ref='modal' centered content-class="remove-project" header-class="p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				span.b1 {{ $t(itemRemoveProject?.canBeDeleted  ? 'project.abandonProject':'project.archiveProject') }}
				icon-close(@click="close")

			.body(v-if="itemRemoveProject")
				p.b3(v-html="$t(itemRemoveProject?.canBeDeleted  ?'project.confirmRemove':'project.confirmArchive',{'title':itemRemoveProject.project.title})")

				.d-flex.justify-content-between
					.btn.btn-border.btn-small.w-100.remove-project__btn-cancel(@click="itemRemoveProject=null") {{ $t('forms.cancel2') }}
					.btn.btn-peril.btn-small.w-100(v-if='itemRemoveProject?.canBeDeleted' @click='removeProject(itemRemoveProject)' :class="{'disabled':  isLoading}")
						.loader(v-if='isLoading')
						span(v-else) {{ $t('forms.abandon') }}
					.btn.btn-primary.btn-small.w-100(v-else @click='archiveProject(itemRemoveProject)' :class="{'disabled':  isLoading}")
						.loader(v-if='isLoading')
						span(v-else) {{ $t('forms.archive') }}
</template>

<script lang="ts">
import ProjectCard from '@/components/Cards/Project.vue';
import { BasketIcon } from '@/components/Svg';
import BasketArchive from '@/components/Svg/BasketArchive.vue';
import { Money } from '@/models/money';
import MyInputSearch from '@/components/UI/MyInputSearch.vue';
import PwBtnIcon from '@/components/Buttons/PwBtnIcon.vue';
import TablePerPage from '@/components/Tables/TablePerPage.vue';
import moment from 'moment-timezone';
import { tablePerPage as tablePerPageNumber } from '@/constants/config';
import { mapActions, mapGetters } from 'vuex';
import ModalGeneral from '@/components/Modals/ModalGeneral.vue';
import ModalRemoveProject from '@/components/Modals/RemoveProject';
import IconClose from '@/components/Common/IconClose.vue';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral.vue';
import DropdownSort from '@/components/Dropdown/DropdownSort.vue';
import MyInput from '@/components/UI/MyInput.vue';
import _ from 'lodash';
import filters from '@/filters';
import PageLoader from '@/components/Widgets/PageLoader';

export default {
    components: {
        MyInput,
        DropdownGeneral,
        DropdownSort,
        IconClose,
        ModalGeneral,
        TablePerPage,
        PwBtnIcon,
        MyInputSearch,
        BasketArchive,
        ProjectCard,
        ModalRemoveProject,
        BasketIcon,
        PageLoader,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        isArchived: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
        },
    },

    data: () => ({
        search: '',
        usedFilters: false,
        itemRemoveProject: null,
        itemCannotBeDeleted: null,
        filters: {
            query: { value: null },
            custom: { value: null },
            itemsPerPage: { value: tablePerPageNumber },
            promotion: { value: null },
            promoCode: { value: null },
            tz: { value: moment.tz.guess() },
            project: { value: null },
            'exists[extraPriceAmount]': { value: null },
            order: { 'project.title': 'desc' },

            merchantPartnerIncomeFirstRu: { from: null, to: null },
            merchantPartnerIncomeFirstWorld: { from: null, to: null },
            merchantPartnerIncomeTotalRu: { from: null, to: null },
            merchantPartnerIncomeTotalWorld: { from: null, to: null },
            'project.conversion': { from: null, to: null },
        },
        currentPages: { value: 1 },
    }),
    created() {
        this.initFilter();
    },
    methods: {
        initFilter() {
            this.filters = {
                ...this.filters,
                ...{
                    merchantPartnerIncomeFirstRu: { from: null, to: null },
                    merchantPartnerIncomeFirstWorld: { from: null, to: null },
                    merchantPartnerIncomeTotalRu: { from: null, to: null },
                    merchantPartnerIncomeTotalWorld: { from: null, to: null },
                    'project.conversion': { from: null, to: null },
                },
            };
        },
        ...mapActions({
            setItem: 'merchantPartner/edit',
            deleteItem: 'merchantPartner/delete',
        }),
        incomeFormat(item, type = 'total') {
            let ret = [];

            switch (type) {
                case 'first':
                    ret.push(new Money(item.merchantPartnerIncomeFirstRu).priceWithCurrency());
                    ret.push(new Money(item.merchantPartnerIncomeFirstWorld).priceWithCurrency());
                    break;
                case 'total':
                    ret.push(new Money(item.merchantPartnerIncomeTotalRu).priceWithCurrency());
                    ret.push(new Money(item.merchantPartnerIncomeTotalWorld).priceWithCurrency());
                    break;
            }
            return ret.join(' | ');
        },
        formatDescription($text) {
            const maxlen = 80;
            if ($text.length > maxlen) {
                return $text.substring(0, maxlen) + '...';
            }
            return $text;
        },
        changeSort(e) {
            const direction = _.first(_.values(this.filters.order));
            this.filters.order = {};
            this.filters.order[e] = direction;
            this.$emit('getProjects', this.filters);
        },
        changeDirection(e) {
            const sort = _.first(_.keys(this.filters.order));
            this.filters.order[sort] = e;
            this.$emit('getProjects', this.filters);
        },
        changePageSize(perPage) {
            this.filters.itemsPerPage.value = perPage;
            this.filters.page = { value: 1 };
            this.$emit('getProjects', this.filters);
        },
        unblock(item) {
            const params = {
                id: item.id,
                isArchived: false,
            };
            this.setItem(params).then(() => {
                this.$emit('getProjects', this.filters);
            });
        },
        archiveProject(item) {
            const params = {
                id: item.id,
                isArchived: true,
            };
            this.setItem(params).then(() => {
                this.itemRemoveProject = false;
                this.$emit('getProjects', this.filters);
            });
        },
        canNotDeleteProject(item) {
            this.itemCannotBeDeleted = item;
            this.$bvModal.show('cannotBeDeleted');
            //this.archiveProject(item)
        },
        canNotBeDeletedClose() {
            this.archiveProject(this.itemCannotBeDeleted);
            this.$bvModal.hide('modalRemove');
        },
        removeProject(item) {
            const params = {
                id: item.id,
            };
            this.deleteItem(params)
                .then(() => {
                    this.itemRemoveProject = false;
                    this.$emit('getProjects', this.filters);
                })
                .catch(e => {
                    if (e === 'Can not be deleted.') {
                        this.canNotDeleteProject(item);
                    }
                });
        },
        submitFilter() {
            this.$emit('getProjects', this.filters);
            // this.$refs.modalFilters.hide();
            this.$bvModal.hide('modalFilters');
        },
        resetFilter() {
            this.initFilter();
            this.$emit('getProjects', this.filters);
            //  this.$refs.modalFilters.hide();
            this.$bvModal.hide('modalFilters');
        },
        close() {
            this.itemRemoveProject = null;
        },
        isShowClear(index) {
            let resultFlag = false;
            let checkFlag = false;
            this.filterList.forEach((itm, i) => {
                if (checkFlag) {
                    if (itm.bigTitle) checkFlag = false;
                    else if (this.filters[itm.code].from || this.filters[itm.code].to) resultFlag = true;
                }
                if (i === index) {
                    if (!itm.bigTitle) return false;
                    if (this.filters[itm.code].from || this.filters[itm.code].to) resultFlag = true;
                    checkFlag = true;
                }
            });
            return resultFlag;
        },
        clear(index) {
            let clearFlag = false;
            this.filterList.forEach((itm, i) => {
                if (clearFlag) {
                    if (itm.bigTitle) clearFlag = false;
                    else {
                        this.filters[itm.code].from = null;
                        this.filters[itm.code].to = null;
                    }
                }
                if (i === index) {
                    clearFlag = true;
                    this.filters[itm.code].from = null;
                    this.filters[itm.code].to = null;
                }
            });
        },
        show() {
            this.$bvModal.show('modalFilters');
            //    this.$refs.modalFilters.show()
        },
        changeRateBadge(item) {
            if (!item.project.rateNew || item.project.rateNew == item.rate) return;
            const pr = item.project;
            const rateChangedAt = filters.dateDDMMMYYDots(pr.rateChangedAt);
            return { rateChangedAt: rateChangedAt, rateNew: pr.rateNew };
        },
    },
    computed: {
        ...mapGetters({
            total: 'merchantPartner/projectsTotal',
        }),
        from() {
            let max = this.currentPages.value * this.filters.itemsPerPage.value;
            return max < this.total ? max : this.total;
        },
        filterList() {
            return [
                {
                    bigTitle: this.$t('merchantPartners.averageIncomeFirst'),
                    title: '(₽) 🇷🇺',
                    code: 'merchantPartnerIncomeFirstRu',
                },
                {
                    title: '($) 🌍',
                    code: 'merchantPartnerIncomeFirstWorld',
                },
                {
                    bigTitle: this.$t('merchantPartners.averageIncome'),
                    title: '(₽) 🇷🇺',
                    code: 'merchantPartnerIncomeTotalRu',
                },
                {
                    title: '($) 🌍',
                    code: 'merchantPartnerIncomeTotalWorld',
                },
                {
                    bigTitle: this.$t('merchantPartners.catalog.conversion') + ' (%)',
                    code: 'project.conversion',
                },
            ];
        },
        dropdownListSort() {
            return [
                {
                    id: 'project.title',
                    title: this.$t('forms.name'),
                },
                {
                    id: 'merchantPartnerIncomeFirstRu',
                    title: this.$t('merchantPartners.averageIncomeFirst') + ' (₽)',
                },
                {
                    id: 'merchantPartnerIncomeFirstWorld',
                    title: this.$t('merchantPartners.averageIncomeFirst') + ' ($)',
                },
                {
                    id: 'merchantPartnerIncomeTotalRu',
                    title: this.$t('merchantPartners.averageIncome') + ' (₽)',
                },
                {
                    id: 'merchantPartnerIncomeTotalWorld',
                    title: this.$t('merchantPartners.averageIncome') + ' ($)',
                },
                {
                    id: 'project.conversion',
                    title: this.$t('project.conversion'),
                },
            ];
        },
        filterUsed() {
            for (const itm in this.filters) {
                // eslint-disable-next-line no-prototype-builtins
                if (this.filters.hasOwnProperty(itm) && !['order'].includes(itm)) {
                    if (this.filters[itm].from || this.filters[itm].to) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    watch: {
        search() {
            clearTimeout(this.queryTimeout);
            this.queryTimeout = setTimeout((data = {}) => {
                this.filters.query.value = this.search;
                this.$emit('getProjects', this.filters);
            }, 500);
        },
        itemRemoveProject() {
            if (this.itemRemoveProject) {
                this.$refs.modal.show();
            } else {
                this.$refs.modal.hide();
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import '~@/assets/css/sass/_mixins.scss';
.bage {
    border-radius: 6px;
    padding: 5px 15px;
}
#cannotBeDeleted {
    .body {
        line-height: 22px;
    }
    .height38 {
        height: 38px;
    }
}
.modal-general-header-disable {
    @include respond-above(md) {
        margin-top: -34px;
    }
}
.modalBlock {
    position: relative;
    .clear {
        position: absolute;
        right: 0;
        top: 0px;
        cursor: pointer;
        color: var(--primary-text-color);
        z-index: 1;
        padding: 3px;
        background: var(--wr-text-color);
        &.clearFirst {
            top: -27px;
        }
    }
    .bigTitle {
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .title {
        margin-bottom: 10px;
    }
    .value {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }
}

.block {
    border-radius: 8px;
    background: var(--button-bg-color);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    @include respond-below(md) {
        font-size: 14px;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-below(md) {
            flex-direction: column;
            gap: 15px;

            .right {
                .dropdown {
                    max-width: 60%;
                }
            }

            .left,
            .right {
                justify-content: space-between;
                width: 100%;
            }
        }

        .left,
        .right {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
            display: flex;
            gap: 30px;
            text-decoration: none;
            border-radius: 8px;
            border: 1px solid var(--border-item-color-line);
            padding: 20px;
            align-items: stretch;

            @include respond-below(md) {
                flex-direction: column;
            }

            .data {
                flex: 2;

                .info {
                    display: flex;
                    gap: 25px;
                    margin-bottom: 25px;

                    img {
                        max-width: 131px;
                        border-radius: var(--border-radius-rounded);
                    }

                    .text {
                        .title {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 10px;
                            color: var(--main-text-color);
                        }

                        .description {
                            color: var(--grey-text-color);
                        }
                    }

                    .bage-danger {
                        font-size: 14px;
                        line-height: 1.57;
                    }

                    @include respond-below(md) {
                        flex-direction: column;

                        img {
                            max-width: 100%;
                        }
                    }
                }

                .btns {
                    display: flex;
                    gap: 10px;

                    .deleteButton {
                        padding: 0 10px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        color: var(--red-text-color);
                        transition: all 0.5s ease;

                        &:hover {
                            color: #ff0000;
                        }
                    }

                    .btn {
                        &.btn-success-light {
                            padding: 8px 34px;
                            background-color: var(--brand-bg-success);
                            border-radius: var(--border-radius-rounded);
                            transition: var(--animation-time-short);
                            color: var(--success-color);
                            border: none;
                            flex: 1;

                            &:hover {
                                background-color: var(--button-border-color);
                                color: white !important;
                            }
                        }

                        &.primary-light {
                            padding: 8px 34px;
                            background-color: var(--light-primary);
                            border-radius: var(--border-radius-rounded);
                            transition: var(--animation-time-short);
                            color: var(--theme-color-1);
                            border: none;
                            flex: 1;

                            &:hover {
                                background-color: var(--button-border-color);
                                color: white !important;
                            }
                        }
                    }
                }
            }

            .stat {
                flex: 3;
                border-radius: 8px;
                background: var(--background-color);
                padding: 20px;
                display: flex;
                justify-content: space-between;
                gap: 20px;
                height: auto;

                .divider {
                    width: 1px;
                    background: var(--border-dropdown-color);
                }

                @include respond-above(lg) {
                    max-width: 400px;
                }

                @include respond-below(lg) {
                    flex-direction: column;

                    .divider {
                        width: 100%;
                        height: 1px;
                    }
                }

                .main,
                .average {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    justify-content: space-between;

                    .title {
                        color: var(--grey-text-color);
                        font-weight: 600;
                        white-space: nowrap;
                    }

                    .line {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .label {
                        }

                        .value {
                            border-radius: 8px;
                            background: var(--background-back);
                            padding: 4px 10px;
                            color: var(--main-text-color);
                            font-weight: 600;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';
.project-list-filter {
    max-width: 335px;
    margin: 0 auto;
    @include respond-below(sm-new) {
        max-width: 100%;
        height: 100%;
    }
    .modal-header {
        position: absolute;
        right: 0;
        top: 25px;
    }
    .modal-body {
        padding: 20px;
    }
}
#modalFilters {
    .modal-general__close {
        z-index: 1;
    }
}
</style>
