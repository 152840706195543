<template lang="pug">
	.d-flex.flex-column.h-100.payment-page.align-items-center(
		v-if="item && item.image"
		:key="keyPayment"
		:class="{'payment-page-main' : isLoad && isReady && !isEmpty && !isArchived && !isBlocked && ((this.item.isNeuro() && this.item.image.contentUrl) || !this.item.isNeuro()), 'is-neuro': this.item.isNeuro()}"
		)

		.payment-page__background(v-if="isLoad && isReady && !isEmpty && !isArchived && !isBlocked" :style="backgroundStyle")

		.d-flex.flex-column.payment-page__content(v-if='isLoad && isReady && !isEmpty && !isArchived && !isBlocked' :class="{'is-page-neurobots' : (item.isNeuro() && !isBuyTokens), 'is-page-tokens' : (isBuyTokens && item.isNeuro())}")
			.d-flex.flex-column

				h1.d-flex.align-items-center.payment-page__title(v-if="isBuyTokens && item.isNeuro()")
					span.d-flex.align-items-center.justify-content-center.border-rounded.cursor-pointer.flex-shrink-0.payment-page__back(:class="{'is-dark': isDarkTheme}" @click="goBack")
						svg(width='19' height='18' viewbox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg')
							path(d='M7.6775 4.44727L3.125 8.99977L7.6775 13.5523' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
							path(d='M15.8764 9H3.25391' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
					span(v-html="$t('tokens.buyTokens')")

				header-payment-bot.payment-page__neurobot(
					v-if='item.isNeuro()'
					:bot="item.bot"
					:image="item.image.contentUrl"
					:isBuyTokens="isBuyTokens"
					:isDarkTheme="isDarkTheme"
					@currencyRUB="d => currencyRUB = d"
				)

				buy-tokens(
					v-if="isBuyTokens && item.isNeuro()"
					:plans="item.plans"
					:currencyRUB="currencyRUB"
					:isDarkTheme="isDarkTheme"
					@buy="openModalBuyTokens"
				)

				payment-tariffs-bot.payment-page__neurobot(
					v-if='item.isNeuro() && !botTrial && !isBuyTokens'
					:plans="item.plans"
					:isDarkTheme="isDarkTheme"
					@currencyRUB="d => currencyRUB = d"
					@trial="botTrial = true"
					@pay="openModalPaymentBot"
				)

				payment-trial-bot.payment-page__neurobot(
					v-if='item.isNeuro() && botTrial && !isBuyTokens'
					:plans="item.plans"
					:isDarkTheme="isDarkTheme"
					:currencyRUB="currencyRUB"
					@allTariffs="botTrial = false, isTrial = true"
					@getFreeAccess="openModalPaymentBot"
				)

				card-project-short(v-if='item && !link && !item.isNeuro()' :item='item' @onExpanded="d => expand = d")
				span.d-flex.cursor-pointer.payment-page__to-tariffs(v-if="expand && showButtonToTariffs" @click="scrollToTariffs && !item.isNeuro()") {{ $t('paymentPage.toTariffs') }}
				.d-flex.flex-column(v-if="!item.isNeuro()" id="payment-page-tariffs")
					tariff-card(v-if="planTrial" :key="`plan-trial-0`" :card="planTrial" :isTrial="true" @click="openModalPaymentCountry({plan: planTrial, isTrial: true})")
					div(v-if="promocode && promocode != null && promocode.plans && !promocode.expired")
						discount-card(
							v-for="(plan, i) in promocodesSort"
							:key="`promo-codes-plan-${i}`"
							:card="promocode"
							:plan='plan'
							:title="promocode.code"
							@done='donePromocode'
							@click="openModalPaymentCountry({plan: plan, promocode: promocode, isTrial: false})"
						)
					div(v-if="promotions" v-for="(item, i) in promotions" :key="`stock-${i}`")
						discount-card(
							v-if='!item.plan.expired'
							:key="`stock-plan-${i}`"
							:card="item"
							:plan="item.plan"
							:title="item.title"
							:cardClass="'promotion'"
							@done='donePromotion'
							@click="openModalPaymentCountry({plan: item.plan, promotion: item, promocode: null, isTrial: false})"
						)

					tariff-card(v-for="(plan, i) in plansWithoutPromo" :key="`plan-${i}`" :card="plan" @click="openModalPaymentCountry({plan: plan, isTrial: false})")
					project-buttons(v-if="isMobile" :promoCode="!isPromoCodesBtn" :isDarkTheme="isDarkTheme" :isPaymentNeurobots="item.isNeuro()" @currencyRUB="d => currencyRUB = d" :partnerLink="partnerLink" )
				project-buttons(v-if="(!isMobile && !item.isNeuro()) || (item.isNeuro() && !isBuyTokens)" :promoCode="!isPromoCodesBtn" :partnerLink="partnerLink" :isDarkTheme="isDarkTheme" :isPaymentNeurobots="item.isNeuro()" @isBuyTokens="d => isBuyTokens = d" @currencyRUB="d => currencyRUB = d")
				footer-payment-new(v-if="!isBuyTokens || !item.isNeuro()" :underLogoText="$t('project.sloganNew')" :rightText="$t('project.rulesAgreement')" :switches="false" :isDarkTheme='isDarkTheme')

		.d-flex.flex-column.align-items-center.m-auto(v-if='isEmpty && !isArchived && !isBlocked')
			router-link(to="/")
				img.payment-page__logo(src="/assets/img/current/logo-blue.svg")
			p.payment-page__text(v-html="$t('project.empty')")
			img.payment-page__img.payment-page__img--copy-writing(src="/assets/img/current/copy-writing.png")

		.d-flex.flex-column.align-items-center.m-auto(v-if='isArchived && !isBlocked')
			router-link(to="/")
				img.payment-page__logo(src="/assets/img/current/logo-blue.svg")
			p.payment-page__text(v-html="$t('project.archivedDesc')")
			img.payment-page__img(src="/assets/img/current/box.png")

		.d-flex.flex-column.align-items-center.m-auto(v-if='isBlocked')
			router-link(to="/")
				img.payment-page__logo(src="/assets/img/current/logo-blue.svg")
			p.payment-page__text(v-html="$t('project.blockedDesc')")
			img.payment-page__img(src="/assets/img/current/ia-computer.png")

		footer-payment(v-if='isEmpty || isArchived ||isBlocked' :underLogoText="isMobile ? currentYear : `2020 - ${currentYear}`" :rightText="$t('h1.serviceRules3')" :switches="false" :isDarkTheme='isDarkTheme')
		modal-cookie

		modal-promt-promocode(v-if='item' :hashid='item.hashId' @onPromt='setPromocode')
		modal-project-complain

		modal-general(v-if="!isMobile && item.isNeuro()" :id="'manage-subscription'" :title="'💼 ' + $t('paymentPage.manageSubscription')" :contentClass="'payment-neurobots__modal'")
			p.b3.payment-page__modal-txt(v-html="$t('paymentPage.txtManageSubscription')")
			a.text-decoration-none(:href="`https://t.me/${item.bot.username}`" target='_blank')
				span.btn.btn-primary(:class="{'is-dark': isDarkColor}") {{ $t('button.goToBot') }}

		b-modal(v-if="isMobile && item.isNeuro()" :id="'manage-subscription'" content-class="bot-setup__modal" header-class="p-0 mb-4 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 💼 {{ $t('paymentPage.manageSubscription') }}
				icon-close(@click="close()")
			p.b3.payment-page__modal-txt(v-html="$t('paymentPage.txtManageSubscription')")
			a.text-decoration-none(:href="`https://t.me/${item.bot.username}`" target='_blank')
				span.btn.btn-primary(:class="{'is-dark': isDarkColor}") {{ $t('button.goToBot') }}

		modal-general(v-if="!isMobile && item.isNeuro()" :id="'no-access-to-bot'" :title="'😥 ' + $t('paymentPage.noAccessToBot')" :contentClass="'payment-neurobots__modal'")
			p.b3.payment-page__modal-txt(v-html="$t('paymentPage.txtNoAccessToBot')")
			a.text-decoration-none(:href="`https://t.me/${item.bot.username}`" target='_blank')
				span.btn.btn-primary(:class="{'is-dark': isDarkColor}") {{ $t('button.goToBot') }}

		b-modal(v-if="isMobile && item.isNeuro()" :id="'no-access-to-bot'" content-class="bot-setup__modal" header-class="p-0 mb-4 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 😥 {{ $t('paymentPage.noAccessToBot') }}
				icon-close(@click="close()")
			p.b3.payment-page__modal-txt(v-html="$t('paymentPage.txtNoAccessToBot')")
			a.text-decoration-none(:href="`https://t.me/${item.bot.username}`" target='_blank')
				span.btn.btn-primary(:class="{'is-dark': isDarkColor}") {{ $t('button.goToBot') }}

		modal-select-country(@onSelect='selectCountry' :isTrial="isTrial && !item.isNeuro()")

		modal-general(
			v-if='selectedTariff || item.isNeuro()'
			:id="'plan'"
			:title="isBuyTokens || !item.isNeuro() && selectedTariff.period.id == 'null' ? $t('h1.paymentOnceConfirm') : $t('h1.paymentConfirm')"
			:contentClass="'content-full payment-confirm'"
		)
			payment-confirm-form(
				:plan="selectedTariff"
				:promocode="selectedPromocode"
				:promotion="selectedPromotion"
				:isTrial="isTrial"
				:country='country'
				:sumrcm='sumrcm'
				:typeNeuro='item.isNeuro()'
				:loginNeuro="item.bot && item.bot.username ? item.bot.username : ''"
				:typeBuyTokens='isBuyTokens'
				:product='product'
				@onConfirm='confirm')

		fb(v-if='analytics.fb_pixel' :id='analytics.fb_pixel' event-name='viewproject' :need='true')
		tt(v-if='analytics.tt_pixel' :id='analytics.tt_pixel' event-name='viewproject')
		ya(v-if='analytics.ya_pixel' :id='analytics.ya_pixel')
		ga(v-if='analytics.ga_pixel' :tid='analytics.ga_pixel')
		gw(v-if='analytics.gw_pixel' :id='analytics.gw_pixel')
		//va(v-if='analytics.vk_ads' :id='analytics.vk_ads')
		//vk(v-if='analytics.vk_pixel' :id='analytics.vk_pixel')
		va(v-if='item && item.getVKAdsId()' :id='item.getVKAdsId()' :ev="'page_view'")
		vk(v-if='item && item.getVKPixelId()' :id='item.getVKPixelId()' role='pixel')
		ga(v-if='item && item.getGAId()' :tid='item.getGAId()')
		ya2(v-if='item && item.getYMId()' :tid='item.getYMId()')
</template>

<script>
import TariffCard from '@/components/Cards/TariffCard';
import DiscountCard from '@/components/Cards/DiscountCard';
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple';
import ThemeSwitch from '@/components/Common/ThemeSwitch';
import IconClose from '@/components/Common/IconClose';
import FooterPaymentNew from '@/containers/FooterPaymentNew';
import FooterPayment from '@/containers/FooterPayment';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import PaymentConfirmForm from '@/components/Form/PaymentConfirm';
import PwBtnLink from '@/components/Buttons/PwBtnLink';

import ModalPromtPromocode from '@/components/Modals/PromtPromocode';
import ModalProjectComplain from '@/components/Modals/ProjectComplain';
import ModalSelectCountry from '@/components/Modals/SelectCountry';
import ProjectButtons from '@/components/Cards/ProjectButtons';

import { PLAN_TABS } from '@/models/project';
import { Promotion } from '@/models/promotion';

import { mapGetters, mapActions } from 'vuex';
import ProjectCardShort from '@/components/Cards/ProjectShortPayment';
import HeaderPaymentBot from '@/components/Cards/HeaderPaymentBot';
import PaymentTariffsBot from '@/components/Cards/PaymentTariffsBot';
import PaymentTrialBot from '@/components/Cards/PaymentTrialBot';
import BuyTokens from '@/components/Tables/BuyTokens';

import VueFacebookPixel from 'vue-analytics-facebook-pixel';
import ModalCookie from '@/components/Modals/Cookie';
import FB from '@/components/Widgets/Facebook';
import TT from '@/components/Widgets/TikTok';
import YA from '@/components/Widgets/YandexCounter';
import GA from '@/components/Widgets/Google';
import YA2 from '@/components/Widgets/Yandex';
import GW from '@/components/Widgets/GoogleAdWords';
import VA from '@/components/Widgets/VKAdsCounter';
import VK from '@/components/Widgets/VKCounter';
import { STORAGE } from '@/api/storage';
import { DENY_RUB_PAYMENTS } from '@/models/project';
import { utmList } from '@/models/utm';
import Switches from 'vue-switches';
import moment from 'moment-timezone';

import Vue from 'vue';
import { defaultColor } from '@/constants/config';

Vue.use(VueFacebookPixel);

export default {
    name: 'Payment',
    props: ['hashId'],
    components: {
        TariffCard,
        DiscountCard,
        LocaleSwitchSimple,
        ThemeSwitch,
        FooterPayment,
        FooterPaymentNew,
        ModalGeneral,
        ModalPromtPromocode,
        ModalProjectComplain,
        ModalSelectCountry,
        ProjectButtons,
        IconClose,
        PaymentConfirmForm,
        PwBtnLink,
        HeaderPaymentBot,
        PaymentTariffsBot,
        PaymentTrialBot,
        BuyTokens,
        'card-project-short': ProjectCardShort,
        fb: FB,
        tt: TT,
        ya: YA,
        ya2: YA2,
        ga: GA,
        gw: GW,
        va: VA,
        vk: VK,
        ModalCookie,
        Switches,
    },
    metaInfo() {
        return { title: this.getTitle };
    },
    data: () => ({
        keyPayment: 0,
        expand: false,
        promocode: null,
        isTrial: false,
        selectedTariff: null,
        selectedPromotion: null,
        selectedPromocode: null,
        country: null,
        showButtonToTariffs: false,
        types: PLAN_TABS,
        projectViewId: null,
        isLoad: false,
        link: null,
        deb: {},
        isDarkTheme: null,
        sumrcm: null,
        rcm: {},
        analytics: STORAGE.getAnalytics(),
        botTrial: false,
        currencyRUB: 0,
        isBuyTokens: false,
        product: {},
    }),
    async created() {
        await this.getProject();

        STORAGE.setAnalytics(
            _.assignIn(this.$route.query, {
                projectId: this.item.id,
                vk_ads: this.item.vkAds,
                vk_pixel: this.item.vkPixel,
            })
        );
        (this.analytics = STORAGE.getAnalytics()), this.sendViewAnalytics();
        this.isLoad = true;

        if (this.isEmpty || this.isArchived || this.isBlocked) this.$emit('available', true);

        if (this.$route.query?.isBuyTokens) this.isBuyTokens = this.$route.query.isBuyTokens === 'true';

        this.viewGeoIpRussia();
    },
    mounted() {
        window.addEventListener('scroll', this.scrollTariffs);
        this.rememberPaymentUTM();
        this.$root.$on('bv::modal::hide', () => {
            if (this.$route.query.rcm) {
                this.$router.replace({ query: {} });
            }
        });
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (this.sumrcm && modalId == 'plan') {
                this.sumrcm = null;
            }
        });

        if (this.isLocaleRu) {
            this.currencyRUB == 0;
        } else {
            this.currencyRUB == 1;
        }
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            email: 'users/paymentEmail',
            promocodes: 'project/promocodes',
        }),
        isReady() {
            return (
                (this.me && this.item && this.item.user) ||
                (!this.me && this.item && this.item.hasSomePlanWithResource()) ||
                this.item.isNeuro()
            );
        },
        isEmpty() {
            return (
                (!this.me &&
                    this.item != null &&
                    ((!this.item.hasPlans() && !this.item.isNeuro()) ||
                        (!this.item.hasSomePlanWithResource() && !this.item.isNeuro()) ||
                        (this.item.isAllPlansArchived() && !this.item.isNeuro()))) ||
                (this.item != null && this.item.isInActive())
            );
        },
        isArchived() {
            return !this.me && this.item != null && this.item.isArchived();
        },
        isBlocked() {
            return this.item != null && this.item.isBlocked();
        },
        isPromoCodesBtn() {
            return this.item.isPromoCodesEmpty;
        },

        partnerLink() {
            if (!this.item.isShowBecomePartnerBtn || !this.item.rate) return;
            return `/partners/${this.item?.hashIdPartner}`;
        },

        getTitle() {
            if (this.item) {
                return this.item.isBlocked() ? 'Проект заблокирован' : this.item.title;
            }

            return 'Проект';
        },
        planTrial() {
            if (!this.item) return null;

            return _.find(this.plans, p => p.isTrial);
        },
        plans() {
            if (!this.item || !this.item.plans || this.item.plans.length == 0) return [];

            return _.sortBy(
                _.filter(this.item.plans, p => !p.isArchived() && p.hasResource()),
                v => Number(v.price.amount)
            );
        },
        plansWithoutPromo() {
            return _.filter(
                _.filter(this.plans, p => {
                    return _.every(this.promotions, pr => pr.plan.id != p.id);
                }),
                pp => {
                    return this.promocode == null || !_.find(this.promocode.plans, ppp => ppp.id == pp.id);
                }
            );
        },
        promotions() {
            let items = [];

            _.each(this.plans, p => {
                _.each(p.promotions, pr => {
                    if (
                        Promotion.isValidNow(
                            pr.startAt ? moment(pr.startAt).add(-3, 'hours') : pr.startAt,
                            pr.endAt == null ? null : moment(pr.endAt).add(-3, 'hours')
                        )
                    ) {
                        let i = _.cloneDeep(pr);
                        i.plan = p;
                        items.push(i);
                    }
                });
            });

            return items;
        },
        promocodesSort() {
            return this.promocode.plans.sort((a, b) => {
                if (Number(a.price.amount) > Number(b.price.amount)) {
                    return 1;
                }
                if (Number(a.price.amount) < Number(b.price.amount)) {
                    return -1;
                }

                return 0;
            });
        },
        currentYear() {
            return new Date().getFullYear();
        },
        backgroundStyle() {
            if (this.item.isNeuro() && !this.item.image.contentUrl) {
                return { background: 'url(/assets/img/neurobots/noAvatar.webp) center / cover no-repeat' };
            } else {
                return { background: `url(${this.item.image.contentUrl}) center / cover no-repeat` };
            }
        },
    },
    methods: {
        ...mapActions({
            hash: 'project/hash',
            hashPost: 'project/hashPost',
            getItem: 'project/id',
            getItems: 'project/items',
            getChannels: 'resources/channels',
            getGroups: 'resources/groups',
            view: 'project/view',
            paymentInit: 'users/paymentInit',
            getPromotions: 'project/promotions',
            getPromocodes: 'project/promocodes',
        }),
        donePromocode(id) {
            if (this.promocode.id == id) {
                this.promocode.expired = true;
                this.promocode = null;
            }
        },
        donePromotion(id) {
            _.each(this.promotions, (pp, i) => {
                if (pp.id == id) this.promotions[i].plan.setExpired(true);
            });

            this.getProject();
        },
        getProject() {
            return new Promise((res, rej) => {
                this.hash({ id: this.hashId })
                    .then(v => {
                        if (this.$route.query.test) {
                            this.deb.hash = this.hashId;
                            this.deb.location = window.location;
                            this.deb.browser = navigator.userAgent;
                            this.deb.responce = v;
                            this.deb.updated = '2021-09-30';
                            rej(false);
                            return false;
                        }

                        if (_.isUndefined(v) || !v.id) {
                            rej(false);
                            this.$router.push({ name: '404' });
                            return;
                        }

                        this.$store.commit('project/setOpened', v);

                        this.isDarkTheme =
                            v.isPaymentThemeDark && localStorage.getItem('themeColorPaymentPage') == null
                                ? v.isPaymentThemeDark
                                : this.getThemeColor().indexOf('dark') > -1;
                        import(
                            '@/assets/css/sass/themes/piaf.' + (this.isDarkTheme ? 'dark' : 'light') + '.figma.scss'
                        );

                        if (this.$route.query.rcm)
                            setTimeout(() => {
                                this.rcm = JSON.parse(atob(this.$route.query.rcm.replace(/_/g, '=')));
                                if (!this.item.isNeuro()) {
                                    this.openModalPaymentCountry({
                                        plan: _.find(this.item.plans, p => p.id == this.rcm.tid),
                                        isTrial: false,
                                    });
                                } else if (this.item.isNeuro()) {
                                    this.openModalPaymentBot(_.find(this.item.plans, p => p.id == this.rcm.tid));
                                }
                            }, 800);

                        res(true);
                    })
                    .catch(v => {
                        if (this.$route.query.test) {
                            this.deb.hash = this.hashId;
                            this.deb.location = window.location;
                            this.deb.browser = navigator.userAgent;
                            this.deb.error = JSON.stringify(v);
                            this.deb.updated = '2021-09-30';
                        }
                        setTimeout(() => {
                            this.hash({ id: this.hashId })
                                .then(v => {
                                    if (_.isUndefined(v) || !v.id) {
                                        rej(false);
                                        this.$router.push({ name: '404' });
                                        return;
                                    }
                                    this.$store.commit('project/setOpened', v);
                                    res(true);
                                })
                                .catch(v => {
                                    setTimeout(() => {
                                        this.hash({ id: this.hashId })
                                            .then(v => {
                                                if (_.isUndefined(v) || !v.id) {
                                                    rej(false);
                                                    this.$router.push({ name: '404' });
                                                    return;
                                                }
                                                this.$store.commit('project/setOpened', v);
                                                res(true);
                                            })
                                            .catch(v => {
                                                rej(false);
                                                this.$router.push({ name: '404' });
                                                return;
                                            });
                                    }, 100);
                                });
                        }, 100);
                    });
            });
        },
        sendViewAnalytics() {
            if (this.item && this.item.id) {
                this.$store.commit('project/setPlans', this.item.plans);

                if (!this.me || (this.item.user && this.item.user.id != this.me.id)) {
                    let viewParams = { id: this.item.id };

                    viewParams.httpReferrer = this.$cookies.get('referrer')
                        ? this.$cookies.get('referrer')
                        : window.document.referrer;

                    if (viewParams.httpReferrer != null)
                        viewParams.httpReferrer = viewParams.httpReferrer.replace(/\/$/, '');
                    if (viewParams.httpReferrer.includes('paywall.pw')) viewParams.httpReferrer = '';

                    _.assignIn(viewParams, this.getUTM());

                    if (this.item.partnerLinkId) viewParams.partnerLinkId = this.item.partnerLinkId;

                    this.view(viewParams).then(v => (this.projectViewId = v.id));
                }
            }
        },
        selectCountry(v) {
            this.country = v;

            if (this.$route.query.rcm) {
                let params = {
                    isForeignCard: this.country == 'en' ? 1 : 0,
                    planId: this.rcm.tid,
                    sid: this.rcm.sid,
                    returnGateway: true,
                };

                this.paymentInit(params)
                    .then(v => {
                        this.sumrcm = v.price;
                        this.$bvModal.show('plan');
                    })
                    .catch(e => this.$notify('error', e));
            } else {
                this.$bvModal.show('plan');
            }
        },
        setPromocode(v) {
            this.promocode = v;
            this.keyPayment++;
        },
        scrollTariffs() {
            if (!this.item.isNeuro()) {
                let tariffs = document.querySelector('#payment-page-tariffs');
                let elementTop = tariffs.offsetTop ? tariffs.offsetTop : 0;
                let screenHeight = window.screen.height;
                this.showButtonToTariffs = window.scrollY < elementTop - screenHeight && elementTop > screenHeight;
            }
        },
        scrollToTariffs() {
            let tariffs = document.querySelector('#payment-page-tariffs');
            let elementTop = tariffs.offsetTop ? tariffs.offsetTop : 0;

            scrollTo(0, elementTop);
        },
        openModalPaymentCountry({ plan = null, promotion = null, promocode = null, isTrial, forsePayment }) {
            if (plan && this.rcm.id && plan.id != this.rcm.id) {
                this.rcm = {};
            }

            if (forsePayment) isTrial = false;

            this.selectedPromotion = promotion;
            this.selectedPromocode = promocode;
            this.selectedTariff = null;
            this.isTrial = isTrial;

            this.$nextTick(() => {
                this.selectedTariff = plan;

                if (this.item.getPixelId() != null) {
                    Vue.analytics.fbq.event('AddToCart', {
                        value: this.item.getPixelClickPurchase(),
                        currency: plan.getCurrency(),
                        content_name: this.item.title,
                    });
                }

                this.$bvModal.show('payment-country');
            });
        },
        openModalPaymentBot(data) {
            if (this.$route.query.rcm) {
                let params = {
                    planId: this.rcm.tid,
                    returnGateway: true,
                    projectId: this.item.id,
                    sid: this.rcm.sid,
                };
                this.paymentInit(params)
                    .then(v => {
                        this.sumrcm = v.price;
                        data.periodYear = data.title == 'Annual' ? true : false;
                        data.currencyRUB = data.price.isCurrencyRUB() ? 1 : 0;
                        data.isTrial = v.isTrial ? v.isTrial : false;

                        this.openModalPayBot(data);
                        this.$router.replace({ query: null });
                    })
                    .catch(e => this.$notify('error', e));
            } else {
                this.openModalPayBot(data);
            }
        },
        openModalPayBot(data) {
            this.isTrial = data.isTrial;

            if (data.isTrial) {
                if (data.periodYear && data.currencyRUB == 1) {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Quarterly' && x.isCurrencyRUB());
                } else if (data.periodYear && data.currencyRUB == 0) {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Quarterly' && !x.isCurrencyRUB());
                }
            } else {
                if (data.periodYear && data.currencyRUB == 1) {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Annual' && x.isCurrencyRUB());
                } else if (data.periodYear && data.currencyRUB == 0) {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Annual' && !x.isCurrencyRUB());
                } else if (!data.periodYear && data.currencyRUB == 1 && data.title == 'Quarterly') {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Quarterly' && x.isCurrencyRUB());
                } else if (!data.periodYear && data.currencyRUB == 0 && data.title == 'Quarterly') {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Quarterly' && !x.isCurrencyRUB());
                } else if (!data.periodYear && data.currencyRUB == 1) {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Monthly' && x.isCurrencyRUB());
                } else if (!data.periodYear && data.currencyRUB == 0) {
                    this.selectedTariff = this.item.plans.find(x => x.title == 'Monthly' && !x.isCurrencyRUB());
                }
            }

            if (data.currencyRUB == 0) this.country = 'en';

            if (data.currencyRUB == 1) this.country = 'ru';

            this.$bvModal.show('plan');
        },
        openModalBuyTokens(data) {
            this.product = data.product;

            if (this.product.name.slice(0, -6).replace(/[^0-9]/g, '') == this.tokenCoins[0] && this.currencyRUB == 1) {
                this.selectedTariff = this.item.plans.find(x => x.tokens == this.tokenCoins[0] && x.isCurrencyRUB());
            } else if (this.product.name.replace(/[^0-9]/g, '') == this.tokenCoins[0] && this.currencyRUB == 0) {
                this.selectedTariff = this.item.plans.find(x => x.tokens == this.tokenCoins[0] && !x.isCurrencyRUB());
            } else if (
                this.product.name.slice(0, -6).replace(/[^0-9]/g, '') == this.tokenCoins[1] &&
                this.currencyRUB == 1
            ) {
                this.selectedTariff = this.item.plans.find(
                    x =>
                        x.title != 'Annual' &&
                        x.title != 'Monthly' &&
                        x.tokens == this.tokenCoins[1] &&
                        x.isCurrencyRUB()
                );
            } else if (this.product.name.replace(/[^0-9]/g, '') == this.tokenCoins[1] && this.currencyRUB == 0) {
                this.selectedTariff = this.item.plans.find(
                    x =>
                        x.title != 'Annual' &&
                        x.title != 'Monthly' &&
                        x.tokens == this.tokenCoins[1] &&
                        !x.isCurrencyRUB()
                );
            } else if (
                this.product.name.slice(0, -6).replace(/[^0-9]/g, '') == this.tokenCoins[2] &&
                this.currencyRUB == 1
            ) {
                this.selectedTariff = this.item.plans.find(x => x.tokens == this.tokenCoins[2] && x.isCurrencyRUB());
            } else if (this.product.name.replace(/[^0-9]/g, '') == this.tokenCoins[2] && this.currencyRUB == 0) {
                this.selectedTariff = this.item.plans.find(x => x.tokens == this.tokenCoins[2] && !x.isCurrencyRUB());
            } else if (
                this.product.name.slice(0, -6).replace(/[^0-9]/g, '') == this.tokenCoins[3] &&
                this.currencyRUB == 1
            ) {
                this.selectedTariff = this.item.plans.find(x => x.tokens == this.tokenCoins[3] && x.isCurrencyRUB());
            } else if (this.product.name.replace(/[^0-9]/g, '') == this.tokenCoins[3] && this.currencyRUB == 0) {
                this.selectedTariff = this.item.plans.find(x => x.tokens == this.tokenCoins[3] && !x.isCurrencyRUB());
            }

            if (data.currencyRUB == 0) this.country = 'en';

            if (data.currencyRUB == 1) this.country = 'ru';

            this.$bvModal.show('plan');
        },
        confirm(v) {
            let d = {
                planId: this.selectedTariff.id,
                description: `План ${this.selectedTariff.title}`,
                projectViewId: this.projectViewId,
                projectId: this.item.id,
            };

            if (v.donate) d.extraPriceAmount = v.donate ? v.donate * 100 : null;
            if (v.email) d.email = v.email;
            if (this.selectedPromocode) d.promoCodeId = Number(this.promocode.id);
            if (this.selectedPromotion) d.promotionId = Number(this.selectedPromotion.id);
            if (this.isTrial) d.isTrial = true;
            if (this.analytics && this.analytics.clickid && this.analytics.projectId == this.item.id)
                d.pixelClickId = this.analytics.clickid;
            if (this.rcm.sid && this.sumrcm) d.sid = this.rcm.sid;
            if (this.rcm.partnerLinkId) d.partnerLinkId = this.rcm.partnerLinkId;

            if (this.item.partnerLinkId) d.partnerLinkId = this.item.partnerLinkId;

            if (this.country == 'en') {
                d.isForeignCard = 1;
                return this.payment(d);
            }

            if (!DENY_RUB_PAYMENTS.includes(this.item.id)) {
                d.isForeignCard = 0;
                return this.payment(d);
            }
        },
        async payment(params) {
            return this.paymentInit(params)
                .then(v => {
                    STORAGE.addPayment({
                        orderId: v.orderId ? v.orderId : v.customData ? v.customData.id : v.id,
                        orderSum: v.amount,
                        orderCurrency: this.selectedTariff.getCurrency(),
                        id: this.item.id,
                        plan: this.selectedTariff.id,
                        planDurationForever: this.selectedTariff.isForever(),
                        url: window.location.href,
                        tg: false,
                        pixelId: this.item.getPixelId(),
                        isDarkTheme: this.isDarkTheme,
                    });

                    if (v && (v.paymentUrl || v.url)) window.location.href = v.paymentUrl || v.url;
                })
                .catch(e => this.$notify('error', e));
        },
        getUTM() {
            let data = {};

            _.each(utmList, i => {
                if (this.$cookies.get(this.hashId + '_' + i)) data[i] = this.$cookies.get(this.hashId + '_' + i);
            });

            if (this.$cookies.get(this.hashId + '_referrer'))
                data.referrer = this.$cookies.get(this.hashId + '_referrer');

            return data;
        },
        rememberPaymentUTM() {
            if (_.some(this.$route.query, (q, i) => i.match(/^utm/))) {
                _.each(utmList, i => this.$cookies.remove(this.hashId + '_' + i, null, window.location.hostname));
                this.$cookies.remove(this.hashId + '_referrer', null, window.location.hostname);
            }

            _.each(this.$route.query, (q, i) => {
                if (i.match(/^utm/)) {
                    let v = i;

                    if (i.match('_')) {
                        v =
                            i.split('_')[0] +
                            i.split('_')[1].charAt(0).toUpperCase() +
                            i.split('_')[1].slice(1).toLowerCase();
                    }

                    this.$cookies.set(this.hashId + '_' + v, q, '1M', null, window.location.hostname, null, 'Lax');
                }
            });

            if (window.document.referrer != null && window.document.referrer.length > 0)
                this.$cookies.set(
                    this.hashId + '_referrer',
                    window.document.referrer,
                    '1M',
                    null,
                    window.location.hostname,
                    null,
                    'Lax'
                );
        },
        getThemeColor() {
            return localStorage.getItem('themeColorPaymentPage')
                ? localStorage.getItem('themeColorPaymentPage')
                : defaultColor;
        },
        goBack() {
            this.isBuyTokens = false;
        },
    },
    watch: {
        expand(val, oldVal) {
            setTimeout(() => {
                this.scrollTariffs();
            }, 300);
        },
        item(nv) {
            if (nv != null) {
                if (this.item.getPixelId() != null) {
                    Vue.analytics.fbq.init(this.item.getPixelId());
                    Vue.analytics.fbq.event('ViewContent', {
                        project: this.item.title,
                        value: this.item.getPixelViewProject(),
                    });
                }

                if (this.item.getVKPixelId() != null) {
                    setTimeout(() => {
                        if (window[this.item.getVKPixelId()])
                            window[this.item.getVKPixelId()].Goal('page_view', { value: 1 });
                    }, 1000);
                }
            }
        },
        isDarkTheme(val, prev) {
            this.isDarkTheme ? document.body.classList.add('dark') : document.body.classList.remove('dark');
            if (prev == null) return;
            let color = this.getThemeColor();
            let isChange = false;

            if (val) {
                isChange = true;
                color = color.replace('light', 'dark');
            } else {
                isChange = true;
                color = color.replace('dark', 'light');
            }

            if (isChange) {
                localStorage.setItem('themeColorPaymentPage', color);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.payment-page {
    position: relative;
    padding: 22px 12px 0;
    overflow: hidden;

    @include respond-above(xm) {
        padding-top: 48px;
    }

    &.payment-page-main {
        min-height: 100vh;

        &::before {
            content: '';
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);

            @include respond-above(sm) {
                position: fixed;
                transform: translate3d(0, 0, 0);
                backdrop-filter: blur(150px);
            }
        }
    }

    &.is-neuro {
        padding-top: 20px;

        @include respond-below(xs) {
            padding-top: 14px;
        }
    }

    &__background {
        position: absolute;
        top: -240px;
        bottom: -240px;
        left: -240px;
        right: -240px;
        filter: blur(150px);
        transform: translate3d(0, 0, 0);

        @include respond-above(sm) {
            position: fixed;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: auto;
            height: auto;
            filter: none;
            transform: none;
            margin: 0;
        }
    }

    &__content {
        max-width: 600px;
        padding-bottom: 34.5px;
        width: 100%;
        position: relative;
        z-index: 10;

        @media (min-width: 576px) {
            padding-bottom: 54.5px;
        }

        &.is-page-neurobots {
            max-width: 624px;
        }

        &.is-page-tokens {
            max-width: 1244px;
            margin-top: -7px;
            padding-bottom: 30px;

            @include respond-below(sm) {
                padding-bottom: 0;
            }
        }
    }

    &__to-tariffs {
        z-index: 1;
        position: fixed;
        right: 17%;
        bottom: 24px;
        padding: 14px 32px 14px 11px;
        font-size: 14px;
        line-height: 16px;
        color: var(--foreground-color) !important;
        border-radius: var(--border-radius-rounded);
        background-color: var(--brand-primary);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
        text-decoration: none !important;

        &::before {
            content: '';
            position: absolute;
            top: 13px;
            right: 10px;
            width: 16px;
            height: 16px;
            mask-image: url('/assets/img/icons/forward-arrow.svg');
            mask-size: cover;
            background-color: var(--foreground-color);
            transform: rotate(90deg);
        }

        &:hover,
        &:active {
            color: var(--foreground-color) !important;
        }

        @include respond-below(lg) {
            right: 10%;
        }

        @include respond-below(md) {
            right: 24px;
        }
    }

    &__item {
        width: 50%;

        @include respond-below(sm) {
            width: 100%;
        }
    }

    &__card {
        border-radius: var(--border-radius-rounded);
        box-shadow: var(--card-box-shadow);
    }

    &__info {
        padding: 16px;
    }

    &__text-wrap {
        max-height: 88px;
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

        &.expand-text {
            max-height: 100rem;
            transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

            .payment-page__text {
                animation: open 0.1s linear 0s forwards;
            }
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        animation: close 0.1s linear 0.1s forwards;
    }

    @keyframes open {
        from {
            -webkit-line-clamp: 4;
        }

        to {
            -webkit-line-clamp: initial;
        }
    }

    @keyframes close {
        from {
            -webkit-line-clamp: initial;
        }

        to {
            -webkit-line-clamp: 4;
        }
    }

    &__switch {
        margin: 0 !important;
    }

    &__buttons {
        padding: 20px;
        background-color: var(--grayscale-bg-dark-back);
        border-radius: var(--border-radius-rounded);

        @include respond-below(sm) {
            padding: 20px 25px;
        }
    }

    &__buttons-wrap {
        margin: 0 0 10px;

        @include respond-below(xl) {
            flex-direction: column;
        }
    }

    &__button {
        max-width: 260px;
        min-width: 260px;
        margin-right: 16px;

        @include respond-below(xl) {
            max-width: 100%;
            min-width: 100%px;
            margin: 0 0 10px;
        }
    }

    &__add-promo {
        max-width: 370px;
        padding: 20px;
        margin: 0 auto;

        @include respond-below(sm) {
            max-width: 100%;
            padding: 16px 16px 28px;
        }
    }

    &__control {
        background-color: var(--grey-bg-color);
        border-radius: var(--border-radius-rounded);

        &.is-focused {
            background-color: var(--background-color);

            .payment-page__input {
                padding: 24px 40px 6px 50px;
            }

            .payment-page__label {
                top: 30%;
            }
        }

        &.is-float {
            background-color: var(--grey-bg-color);

            .payment-page__input {
                padding: 24px 40px 6px 50px;
            }

            .payment-page__label {
                top: 30%;
            }
        }

        &.is-error {
            background-color: transparent;

            .payment-page__input {
                border: 1.5px solid var(--red-bg-color) !important;

                &:hover,
                &:focus {
                    border: 1px solid var(--red-bg-color) !important;
                }
            }

            &::before {
                background-color: var(--red-text-color);
            }
        }
    }

    &__input {
        z-index: 1;
        align-items: center;
        height: 52px;
        padding: 15px 40px 15px 50px;
        border: 1.5px solid transparent;
        background-color: transparent;
        border-radius: var(--border-radius-rounded);
        transition: var(--animation-time-short);
        cursor: text;

        &:hover {
            background-color: transparent !important;
            border: 1.5px solid var(--button-border-color) !important;
        }

        &:focus {
            background-color: transparent !important;
            border: 1.5px solid var(--button-border-color) !important;
        }
    }

    &__label {
        position: absolute;
        top: 50%;
        left: 50px;
        color: var(--grey-text-color);
        transform: translateY(-50%);
        cursor: text;
        transition: var(--animation-time-short);
    }

    &__reset {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: 19px;
        transform: translateY(-50%);
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }

    &__payment-country {
        max-width: 381px;
        padding: 20px;
        margin: 0 auto;

        @include respond-below(sm) {
            max-width: 100%;
            padding: 16px 20px 36px;
        }

        .modal-header {
            margin-bottom: 15px !important;

            @include respond-below(sm) {
                margin-bottom: 21px !important;
            }
        }

        .modal-body {
            .pw-btn-payment {
                @include respond-below(sm) {
                    margin-bottom: 22px;
                }
            }
        }
    }

    &__logo {
        width: 139px;
        height: 36px;
        margin-bottom: 32px;

        @include respond-below(md) {
            width: 113px;
            height: 27px;
            margin-bottom: 30px;
        }
    }

    &__text {
        font-size: 32px;
        font-weight: 400;
        line-height: 50px;
        text-align: center;

        @include respond-below(md) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__img {
        width: 348px;

        @include respond-below(md) {
            width: 208px;
        }

        &--copy-writing {
            margin-top: 55px;
            margin-left: 72px;
            margin-bottom: 35px;

            @include respond-below(md) {
                margin-top: 35px;
                margin-left: 46px;
            }
        }
    }

    &__neurobot {
        margin-bottom: 20px;

        @include respond-below(xs) {
            margin-bottom: 11px;
        }
    }

    &__modal {
        &-txt {
            margin-top: 5px;
            margin-bottom: 24.5px;

            @include respond-below(sm) {
                margin-top: 2px;
                margin-bottom: 35px;
            }
        }
    }

    &__title {
        margin-top: 8px !important;
        margin-bottom: 44.5px !important;
        font-weight: 500 !important;
        font-size: 45px !important;
        line-height: 54px;
        color: #fff;

        @include respond-below(lg) {
            margin-right: 24.5px !important;
            margin-left: 24.5px !important;
        }

        @media (max-width: 768px) {
            margin-bottom: 45px !important;
            margin-top: 16px !important;
            margin-right: 12.5px !important;
            margin-left: 12.5px !important;
        }

        @include respond-below(xs) {
            font-weight: 600 !important;
            font-size: 24px !important;
            line-height: 28px !important;
            max-width: 420px;
            margin: 16px auto 24px !important;
            width: 100%;
        }
    }

    &__back {
        width: 43px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.2) !important;
        margin-right: 26px;
        transition: background-color 0.4s ease;

        @include respond-below(sm) {
            margin-right: 25px;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.3) !important;
        }

        &.is-dark {
            background-color: rgba(0, 0, 0, 0.2) !important;

            &:hover {
                background-color: rgba(0, 0, 0, 0.3) !important;
            }
        }
    }
}

#add-promo-code + .modal-backdrop,
#payment-country + .modal-backdrop,
#plan + .modal-backdrop,
#complain + .modal-backdrop,
#manage-subscription + .modal-backdrop,
#no-access-to-bot + .modal-backdrop {
    opacity: 0.7 !important;
}
</style>
