<template lang="pug">
	.catalog-item
		MPLAlerts
		h2.mb-4(v-html="pageTitle")

		b-tabs(nav-class='tabs-filled tabs-filled-transparent' v-model='tab' no-fade  v-if="item")
			b-tab(:title="$t('menu.dashboard')")
				b-row
					b-colxx(sm='8' md='7')
						b-card.dashboard-filled-line-chart.mb-4(no-body v-if="item.project.isActive() && !item.isArchived")
							b-card-body.flex-grow-initial
								.title.mb-2(v-html="$t('merchantPartners.catalogItem.yourLink')")
								.text-grey.mb-2(v-html="$t('merchantPartners.catalogItem.yourLinkDescr')")

								.inputBlock
									.value
										a(:href="partnerLink" target="_blank" v-html="partnerLink")
									.btns
										.button.copy(v-if="!isMobile" @click="copy(partnerLink)")
											i.fontello.fontello-icon-copy
										.button.copy(v-else )
											a.fontello.fontello-icon-link-1.text-decoration-none(:href="partnerLink" target="_blank")
										.button.edit(@click="openModalQRCode()")
											i.fontello.fontello-icon-scan-barcode-1

						Income.mb-4(v-if="stats && openedProject")

						SubscriptionsStatistics.dashboard__indent.mb-4(
							v-if='stats && item'
							:active='stats.subs.subscriptionsActive'
							:legendsToShow="['active']"
						)

						ActivityStatistics(:id='item?.project?.id')




					b-colxx(sm='4' md='5')
						ProjectCardShort(v-if='item && item.project' :item='item.project' :merchantPartnerItem="item" contentClass="project-partner-short--dashboard")

						b-card.project-short.dashboard-filled-line-chart.mb-3(no-body)
							b-card-body.flex-grow-initial
								.d-flex.align-items-center.justify-content-between
									.b1.text-bold(v-html="$t('project.yourBid')")
									.d-flex.align-items-center
										span.b2.text-bold.text-primary {{item.rate}}%
										question.ml-2(:id="!isMobile ? `tooltip-conversion2` : ''" @click="isMobile ? $bvModal.show(`tooltip-conversion2`) : ''" color="grey")
										modal-tooltip(
											id="tooltip-conversion2"
											:text="`${$t('partners.become.stateDescription')}`"
											:textMobile="`${$t('partners.become.stateDescription')}`"
										)
								b-badge.mt-1.bage(v-if="changeRateBadge" variant='orange' v-html="$t('merchantPartners.alerts.changeRate',changeRateBadge)")


						//b-card.project-short.dashboard-filled-line-chart.mb-3(no-body)
							b-card-body.flex-grow-initial
								.mb-3.b1.text-bold(v-html="$t('partners.become.financeTitle')")
								.text-grey.mb-3(v-html="$t('partners.become.financeDescription')")
								PartnersAverageIncome(:value="{first:{RUB:item.merchantPartnerIncomeFirstRu,USD:item.merchantPartnerIncomeFirstWorld},total:{RUB:item.merchantPartnerIncomeTotalRu,USD:item.merchantPartnerIncomeTotalWorld}}" :isVertical="true")

						b-card.project-short.dashboard-filled-line-chart.mb-3(no-body)
							b-card-body.flex-grow-initial
								.b1.text-bold(v-html="$t('merchantPartners.catalogItem.projectSubscription')")
								.blue-box
									.title(v-html="$t('merchantPartners.catalogItem.averageConversion')")
									span.b2.text-bold.text-primary {{item.project.conversion}}%
								.link.text-primary.cursor-pointer(v-html="$t('landing.cookieDesc')" @click="$bvModal.show('income-detail')")

				ModalPlansIncome(:averageIncome="item" :conversion="item.project.conversion")

			b-tab(:title="$t('project.analytics.title')")
				form-project-analytics(v-if="openedProject")

		modal-qr-code(v-if="openedProject" :projectImage='openedProject.image?.contentUrl' :projectName='openedProject.title' :qrPaymentPage="qrPaymentPage")

</template>

<script>
import ProjectAnalytics from '@/components/Form/ProjectAnalytics.vue';

import SubscriptionsStatistics from '@/components/Cards/SubStatistics';
import { mapGetters, mapActions } from 'vuex';
import { STATUS_REMOVED, STATUS_ACTIVE } from '@/models/model';
import ProjectAlerts from '@/components/Cards/ProjectAlerts.vue';
import ProjectCardShort from '@/components/Cards/ProjectShort.vue';
import PartnersAverageIncome from '@/components/Common/PartnersAverageIncome.vue';
import Question from '@/components/Icons/question.vue';
import ModalTooltip from '@/components/Modals/ModalTooltip.vue';
import Income from '@/components/Cards/Income.vue';
import MPLAlerts from '@/components/Cards/MPLAlerts.vue';
import moment from 'moment-timezone';
import ActivityStatistics from '@/components/Cards/ActivityStatistics.vue';
import ModalPlansIncome from '@/components/Modals/PlansIncome.vue';
import MerchantPartnerCanInviteSubscribers from '@/components/Modals/MerchantPartnerCanInviteSubscribers.vue';
import PwAlert from '@/components/Form/PwAlert.vue';
import filters from '@/filters';
import ModalQRCode from '@/components/Modals/ModalQRCode';

export default {
    name: 'CatalogItem',
    props: ['id'],
    components: {
        PwAlert,
        ActivityStatistics,
        Income,
        MPLAlerts,
        ModalTooltip,
        Question,
        SubscriptionsStatistics,
        PartnersAverageIncome,
        ProjectCardShort,
        ProjectAlerts,
        ModalPlansIncome,
        MerchantPartnerCanInviteSubscribers,
        'form-project-analytics': ProjectAnalytics,
        'modal-qr-code': ModalQRCode,
    },
    metaInfo() {
        return { title: this.getTitle };
    },
    data: () => ({
        tab: 0,
        isAccountsLoaded: false,
    }),
    computed: {
        ...mapGetters({
            projectList: 'project/itemsShort',
            item: 'merchantPartner/detail',
            openedProject: 'project/opened',
            stats: 'project/statistics',
            plans: 'plans/list',
            planEdited: 'plans/edited',
            accountList: 'finance/accounts',
            accountListActiveRemoved: 'finance/accountsActiveRemoved',
            activeCards: 'finance/activeCards',
            isPendingProject: 'project/isPending',
            isGettingProject: 'project/GETTING_PROJECT',
            metrics: 'project/metrics',
            qrPaymentPage: 'project/qrPaymentPage',
        }),
        pageTitle() {
            return this.item?.title ?? this.$t('h1.project');
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('partner.', '')}/`;
        },
        partnerLink() {
            return `${this.paymentLinkDomain}${this.item?.hashId}`;
        },
        changeRateBadge() {
            if (!this.item.project.rateNew || this.item.project.rateNew == this.item.rate) return;
            const pr = this.item.project;
            const rateChangedAt = filters.dateDDMMMYYDots(pr.rateChangedAt);
            return { rateChangedAt: rateChangedAt, rateNew: pr.rateNew };
        },
    },
    methods: {
        ...mapActions({
            activate: 'project/activate',
            getItem: 'merchantPartner/id',
            getPlans: 'plans/items',
            getChannels: 'resources/channels',
            getGroups: 'resources/groups',
            edit: 'project/edit',
            attachAccount: 'project/attachAccount',
            getAccounts: 'finance/accounts',
            getActiveCards: 'finance/activeCards',
            getStatistics: 'project/statistics',
            getAccountsFinance: 'users/finance',
            getQrPaymentPage: 'merchantPartner/qrPaymentPage',
            getItemByHash: 'project/hash',
        }),

        async initProject() {
            if (this.item && this.item?.id != this.id) {
                this.$store.commit('project/setOpened', null);
            }
            this.$store.commit('project/setStatistics', null);

            await this.getItem({ id: this.id });

            this.getStatistics({
                id: this.item.project.id,
                tz: moment.tz.guess(),
            });

            await this.getQrPaymentPage({ id: this.id }).then(v => {
                this.$store.commit('project/setQrPaymentPage', v);
            });

            this.$store.commit('plans/list', this.item.plans);
            this.$store.commit('resources/channels', this.item.channels);
            this.$store.commit('resources/groups', this.item.groups);

            this.isReady = true;

            await this.getAccounts({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 });
            await this.getAccountsFinance({
                status: [STATUS_ACTIVE, STATUS_REMOVED],
                itemsPerPage: 1000,
            }).then(list => {
                _.each(this.accountList, a => {
                    _.each(list, l => {
                        if (a.id == l.id) {
                            a.setFinance(l);
                        }
                    });
                });
            });

            let a = _.filter(this.accountList, la => {
                return _.filter(this.item.accounts, aa => aa.id == la.id).length > 0;
            });

            this.$store.commit('project/setAccounts', a);
            this.$store.commit('finance/setAccountsActiveRemoved', this.accountList);
            this.me.setAccounts(this.accountListActiveRemoved);
            this.isAccountsLoaded = true;
            this.getActiveCards();
        },

        goToFinance() {
            this.$router.push({ name: 'finance', query: { editProfile: true } });
        },

        hasOpenedProjectBefore() {
            return this.id && this.id != 'unknown';
        },

        setTab(tab) {
            if (tab || tab == 0) return (this.tab = tab);
            if (this.$route.params && this.$route.params.tab) this.tab = this.$route.params.tab;
        },
        async copy(text) {
            try {
                await this.$copyText(text);
                this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('success.copyLink')}`);
            } catch (e) {
                console.log(e);
            }
        },

        openModalQRCode() {
            this.$bvModal.show(`show-qr-code`);
        },
    },
    created() {
        this.initProject();
    },
    mounted() {
        //       this.$bvModal.show(`mp-can-invite-subscribers`);
    },
    watch: {
        id(nv, ov) {
            if (nv && ov && nv != ov) {
                this.initProject();
            }
        },

        item: {
            handler(nv, ov) {
                this.$store.commit('project/setOpened', nv.project);
            },
            deep: true,
        },

        me: {
            handler() {},
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/sass/_mixins.scss';
.bage {
    border-radius: 6px;
    padding: 5px 15px;
}
.blue-box {
    border-radius: 8px;
    background: var(--light-primary);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
}
.subscribersCount {
    border-radius: 15px;
    background: var(--brand-linght-success);
    text-align: center;
    padding: 10px 16px;

    .title {
        color: var(--success-color);
        margin-bottom: 4px;
    }
    .value {
        font-size: 18px;
        color: var(--trialColor);
    }
}
.inputBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background: var(--background-color);
    border-radius: 15px;
    padding: 10px 20px;

    .value {
        font-size: 18px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            font-size: 16px;
            font-weight: 400;
            line-height: 2.5;
        }
    }
    .btns {
        display: flex;
        gap: 9px;
        align-items: center;

        .button {
            background: var(--button-bg-color);
            border-radius: 8px;
            width: 50px;
            height: 50px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary-text-color);
            transition: all 0.3s ease;

            &:hover {
                color: var(--primary-dark-text-color);
            }
        }
    }
    @include respond-below(sm) {
        background: transparent;
        padding: 0;

        .value {
            display: none;
        }
        .btns {
            width: 100%;

            .button {
                background: var(--theme-color-1-10);
                width: 50%;
            }
        }
    }
}
</style>
