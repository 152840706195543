import { getSPARole, DOMAIN_PROD } from '@/api';
import { USER_ROLES } from '@/models/user';
import { STORAGE_LAST_OPENED_ITEM } from '@/api/storage';

const line = { isLine: true };

export class MenuFabric {
    static getItems(item) {
        // eslint-disable-next-line no-undef
        let lastOpenedProjectId = $cookies.get(STORAGE_LAST_OPENED_ITEM);
        let empty = 'unknown';

        const dashboard = MenuItem('dashboard', 'fontello fontello-icon-dashboard', 'menu.dashboard', {
            name: 'project_dashboard',
            params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty },
        });
        const resources = MenuItem(
            'resources',
            'fontello fontello-icon-channels-and-chats',
            'project.resources',
            { name: 'project_resources', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            null,
            item ? item.isNeuro() : null
        );
        const bot = MenuItem(
            'bot',
            'main-menu__item-bot',
            'project.bot',
            { name: 'project_bot', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            null,
            item ? !item.isNeuro() : null
        );
        const tariffs = MenuItem(
            'plans',
            'fontello fontello-icon-plans',
            'promotions.promotionsInfo.tariffsAndPromotions',
            { name: 'project_plans', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            'projectHasResources',
            item ? item.isNeuro() : null
        );
        const tariffsBot = MenuItem(
            'tariffs',
            'fontello fontello-icon-plans',
            'promotions.promotionsInfo.tariffs',
            { name: 'tariffs', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            null,
            item ? !item.isNeuro() : null
        );
        const featuresBot = MenuItem(
            'features',
            'main-menu__item-features',
            'project.features',
            { name: 'features', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            null,
            item ? !item.isNeuro() : null
        );
        const audience = MenuItem(
            'audience',
            'main-menu__item-audience',
            'project.audience',
            { name: 'audience', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            'isProjectReady',
            item ? !item.isNeuro() : null
        );
        const followers = MenuItem(
            'followers',
            'fontello fontello-icon-subscriptions',
            'project.followersAmount2',
            { name: 'project_followers', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            'canSeeMenuItems',
            item ? item.isNeuro() : null
        );
        const partners = MenuItem(
            'partners',
            'fontello fontello-icon-briefcase',
            'h1.partners',
            { name: 'project_partners', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            'canSeeMenuItemPartner',
            item ? item.isNeuro() : null,
            'isPartnerNew'
        );
        const profile = MenuItem('profile', 'fontello fontello-icon-profile-circle', 'project.profile', {
            name: 'profile',
            params: {},
        });
        const analytics = MenuItem(
            'analytics',
            'fontello fontello-icon-analytics',
            'project.analytics.title',
            { name: 'project_analytics', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null,
            'canSeeMenuItems'
        );
        const settings = MenuItem(
            'settings',
            'fontello fontello-icon-settings',
            'h1.settings',
            { name: 'project_settings', params: { id: lastOpenedProjectId ? lastOpenedProjectId : empty } },
            null,
            false,
            null
        );
        const finance = MenuItem(
            'finance',
            'fontello fontello-icon-accounts',
            'h1.finance',
            { name: 'finance' },
            null,
            null,
            'financeAmount',
            'isProfileFilled'
        );
        const logout = MenuItem('logout', 'fontello fontello-icon-log-out', 'h1.logout', { name: 'logout' });
        const tutorials = MenuItem(
            'tutorials',
            'fontello fontello-icon-knowledge-base',
            'h1.tutorials',
            'https://docs.paywall.pw/rus/',
            null,
            true
        );
        const offer = MenuItem('offer', 'fontello fontello-icon-dashboard', 'h1.offer', { name: 'offer' });
        const blog = MenuItem(
            'blog',
            'fontello fontello-icon-book',
            'h1.blog',
            'https://' + DOMAIN_PROD + '/blog',
            null,
            true
        );
        const support = MenuItem(
            'support',
            'fontello fontello-icon-support',
            'h1.support',
            { id: 'modal-support', button: true },
            null,
            false,
            null
        );

        switch (getSPARole()) {
            case USER_ROLES.PARTNER:
                return [offer, finance, support, line, logout];
            case USER_ROLES.MERCHANT_PARTNER: {
                const MPdashboard = MenuItem('dashboard', 'fontello fontello-icon-dashboard', 'menu.dashboard', {
                    name: 'dashboard',
                });
                const MPProjectCatalog = MenuItem('catalog', 'fontello fontello-icon-projects', 'menu.catalog', {
                    name: 'catalog',
                });
                const MPfinance = MenuItem(
                    'finance',
                    'fontello fontello-icon-accounts',
                    'h1.finance',
                    { name: 'finance' },
                    null,
                    null,
                    'financeAmount'
                );
                const MPtutorials = MenuItem(
                    'tutorials',
                    'fontello fontello-icon-knowledge-base',
                    'h1.tutorials',
                    'https://docs.paywall.pw/rus/obshee/partnerskaya-programma',
                    null,
                    true
                );
                const MPNotifications = MenuItem(
                    'notifications',
                    'fontello fontello-icon-notification',
                    'menu.notifications',
                    { name: 'notifications' }
                );

                return [
                    MPdashboard,
                    MPProjectCatalog,
                    MPNotifications,
                    line,
                    profile,
                    MPfinance,
                    line,
                    MPtutorials,
                    support,
                    line,
                    logout,
                ];
            }
        }

        return [
            dashboard,
            resources,
            bot,
            tariffs,
            tariffsBot,
            featuresBot,
            audience,
            followers,
            partners,
            analytics,
            settings,
            line,
            profile,
            finance,
            line,
            blog,
            tutorials,
            support,
            line,
            logout,
        ];
    }
}

function MenuItem(id, icon, label, to, subs, newWindow, amount, fnDisable, fnHide, fnNew) {
    return {
        id: id,
        icon: icon,
        label: label,
        to: to,
        subs: subs ? subs : null,
        newWindow: newWindow,
        amount: amount,
        fnDisable: fnDisable,
        fnHide: fnHide,
        fnNew: fnNew,
    };
}
