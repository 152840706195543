<template lang="pug">
	.landing
		div
			.landing__monetization
				Header(@login="goToLogin")
				.landing__plain
					.pt-0.landing__container(ref="container")
						Preview(
							:type="'plain'"
							:title="$t('landingPage.directions.monetization.title')",
							:description="$t('landingPage.directions.monetization.txt')",
							:button="$t('landingPage.preview.monetization.button')"
							@click="goToLogin"
						)
				Authors(:marginLeftContainer="marginLeftContainer")
				Reviews
				Write(v-if="islocaleRuSystemAndIp")
				.overflow-hidden.landing__container
					Steps
				.landing__container(v-if="islocaleRuSystemAndIp")
					Features(@click="goToLogin")
				Commission(@click="goToLogin")
				Support

				.landing__container(v-if="islocaleRuSystemAndIp")
					Articles
				Footer(v-if="!islocaleRuSystemAndIp")
				FooterRuDomain(v-if="islocaleRuSystemAndIp")

			ModalCookie
</template>

<script>
import { mapActions } from 'vuex';
import { DEVICE } from '@/utils/device';

/*Общий*/
import Header from '@/components/landingPage/Header';
import Preview from '@/components/landingPage/Preview';
import Footer from '@/components/landingPage/Footer';
import FooterRuDomain from '@/components/landingPage/FooterRuDomain';
import ModalCookie from '@/components/Modals/Cookie';

/*Монетизация*/
import Authors from '@/components/landingPage/Plain/Authors';
import Reviews from '@/components/landingPage/Plain/Reviews';
import Write from '@/components/landingPage/Plain/Write';
import Steps from '@/components/landingPage/Plain/Steps';
import Features from '@/components/landingPage/Plain/Features';
import Commission from '@/components/landingPage/Plain/Commission';
import Support from '@/components/landingPage/Plain/Support';
import Articles from '@/components/landingPage/Plain/Articles';

export default {
    name: 'landingContent',
    metaInfo() {
        return {
            titleTemplate: this.islocaleRuSystemAndIp
                ? 'Paywall - монетизация контента, сообществ и нейросетей в Телеграме.'
                : 'Paywall - Content monetization service',
        };
    },
    components: {
        Header,
        Preview,
        Footer,
        FooterRuDomain,
        ModalCookie,

        Authors,
        Reviews,
        Write,
        Steps,
        Features,
        Commission,
        Support,
        Articles,
    },
    data: () => ({
        marginLeftContainer: '',
    }),
    created() {
        this.viewGeoIpRussia();
    },
    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), 100);
        }

        this.rememberPageView();

        this.$nextTick(() => {
            const fixedMarginLeft = DEVICE.isMobile() ? '12.5px' : '16px';

            const containerElement = this.$refs.container;
            const computedStyles = window.getComputedStyle(containerElement);
            const computedMarginLeft = computedStyles.getPropertyValue('margin-left');
            this.marginLeftContainer =
                parseFloat(computedMarginLeft) > parseFloat(fixedMarginLeft) ? computedMarginLeft : fixedMarginLeft;

            if (parseInt(this.marginLeftContainer) >= 184) {
                this.marginLeftContainer = '184px';
            }
        });
    },
    beforeMount() {
        this.redirectToLoginIfMerchantPath();
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name != 'landingContent') {
                if (to.params.block) {
                    let distanceBlock = 0;

                    if (!to.params.distance) {
                        distanceBlock = -30;
                    } else {
                        distanceBlock = to.params.distance;
                    }

                    vm.$nextTick(() => {
                        const targetBlock = document.querySelector(to.params.block);

                        setTimeout(() => {
                            const top = window.scrollY + targetBlock.getBoundingClientRect().y + distanceBlock;

                            window.scrollTo(0, top);
                        }, 1000);
                    });
                }
            }
        });
    },
    methods: {
        ...mapActions({
            getGeoIpRussia: 'auth/geoIpRussia',
        }),
        redirectToLoginIfMerchantPath() {
            if (window.location.href.includes('merchant')) {
                if (this.me && this.me.id) return this.$router.replace({ name: 'projects' });

                return this.goToLogin();
            }
        },
        goToLogin(e) {
            if (typeof e === 'string') {
                switch (e) {
                    case 'merchant':
                        window.location.href = this.loginUrl;
                        break;
                    case 'partner':
                        window.location.href = this.loginPartnerUrl;
                        break;
                }
                return;
            }
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            window.location.href = this.loginUrl;
        },
        handleBeforeUnload() {
            window.scrollTo({ top: 0, behavior: 'instant' });
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_landing.style.scss';
</style>
