<template lang="pug">
	.dashboard
		h2.mb-4 {{ $t('menu.notifications') }}
		.page
			.list-wrap
				.list
					.item(v-for="item in list")
						img.image(:src="item.contentUrl")
						.data
							.value(v-html="notificationFormatted(item)")
							.date {{item.createdAt | dateHHmmDDMMYYYY}}
						.pin(v-if="isNew(item)")
				.more(@click="loadData" v-if="!isLast" v-html="$t('merchantPartners.showMore')")

				.empty.text-center(v-if="!list.length")
					h1.mb-3.text-center 😢
					span.b3.text-center.text-grey {{ $t('errors.emptySearch') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MerchantPartnerNotifications',

    mounted() {
        this.loadData();
    },
    metaInfo() {
        return { title: this.$t('menu.notifications') };
    },
    methods: {
        ...mapActions({
            getNotifications: 'merchantPartner/notifications',
            getMe: 'auth/me',
        }),
        async loadData() {
            await this.getList();
            // if(this.me.hasNotifications){
            //     this.getMe();
            // }
        },
        async getList() {
            const params = { lastDate: this.lastDate };
            await this.getNotifications(params);
            //    if (this.isAllNew() && this.total > this.list.length) await this.getList();
        },
        isAllNew() {
            const allList = this.list.map(itm => this.isNew(itm));
            return allList.every(Boolean);
        },
        isNew(item) {
            return new Date(this.me.notificationReadAt) < new Date(item.createdAt);
        },
        notificationFormatted(item) {
            let params = {
                ...item.date,
                projectTitle: item.title,
            };
            switch (item.message) {
                case 'RATE_CHANGE':
                case 'RATE_CHANGE_BACK':
                case 'PROJECT_BLOCKED':
                    return this.$t('merchantPartners.notification.' + item.message, params);
            }
            return item.message;
        },
    },
    computed: {
        ...mapGetters({
            list: 'merchantPartner/notifications',
            isLast: 'merchantPartner/notificationsIsLast',
            me: 'auth/me',
        }),
        lastDate() {
            return this.list[this.list.length - 1]?.createdAt;
        },
    },
    beforeRouteLeave(to, from, next) {
        this.getMe();
        next();
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';
.page {
    .list-wrap {
        padding: 20px;
        border-radius: 8px;
        background: var(--foreground-color);
    }
    .list {
        .item {
            display: flex;
            gap: 15px;
            padding: 10px 0 20px 0;
            border-bottom: 1px solid var(--border-dropdown-color);

            &:last-child {
                border-bottom: none;
            }
            .image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
            }

            .data {
                display: flex;
                flex-direction: column;
                gap: 5px;
                flex: 1;

                .value::v-deep {
                    .title {
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 5px;
                        line-height: 1.6;
                    }
                    .description {
                        color: var(--grey-text-color);
                        line-height: 1.57;
                    }
                }
                .date {
                    color: var(--brand-color-disabled-arrow);
                }
            }
            .pin {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                overflow: hidden;
                background: #ff7c7c;
            }
        }
    }
    .more {
        color: var(--theme-color-1);
        padding-left: 20px;
        cursor: pointer;
    }
}
</style>
