<template lang="pug">
	div(v-if='me && isAccountsLoadedAndReady')
		.finance
			.d-flex.align-items-center.mb-4(v-if="me.hasFilledPaymentAccounts()")
				h2.mr-2 {{ $t('h1.finance') }}
				a(href="https://docs.paywall.pw/rus/obshee/finansy" target="_blank")
					svg.cursor-pointer(xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none")
						circle(cx="14" cy="14" r="14" :fill="isDarkColor ? '#27282A' : '#FFF'")
						path(d="M13.58 15.5867C13.58 15.3502 13.5924 15.1324 13.6173 14.9333C13.6547 14.7218 13.7231 14.5164 13.8227 14.3173C13.9347 14.1058 14.084 13.888 14.2707 13.664C14.4698 13.44 14.7311 13.1911 15.0547 12.9173C15.5773 12.4693 15.9756 12.0649 16.2493 11.704C16.5231 11.3431 16.66 10.9573 16.66 10.5467C16.66 9.94933 16.4609 9.45778 16.0627 9.072C15.6644 8.68622 15.1169 8.49333 14.42 8.49333C13.7231 8.49333 13.1756 8.68622 12.7773 9.072C12.3791 9.45778 12.18 9.94933 12.18 10.5467H10.5C10.5 10.0364 10.5871 9.56978 10.7613 9.14667C10.948 8.71111 11.2093 8.33778 11.5453 8.02667C11.8938 7.70311 12.3107 7.45422 12.796 7.28C13.2813 7.09333 13.8227 7 14.42 7C15.0173 7 15.5587 7.09333 16.044 7.28C16.5293 7.45422 16.94 7.70311 17.276 8.02667C17.6244 8.33778 17.8858 8.71111 18.06 9.14667C18.2467 9.56978 18.34 10.0364 18.34 10.5467C18.34 10.8951 18.284 11.2249 18.172 11.536C18.06 11.8471 17.9044 12.1458 17.7053 12.432C17.5187 12.7058 17.3009 12.9733 17.052 13.2347C16.8031 13.4836 16.548 13.72 16.2867 13.944C16.0751 14.1182 15.9009 14.2862 15.764 14.448C15.6271 14.5973 15.5151 14.7467 15.428 14.896C15.3533 15.0329 15.2973 15.176 15.26 15.3253C15.2351 15.4622 15.2227 15.6116 15.2227 15.7733V16.1467H13.58V15.5867ZM14.42 20.44C14.0591 20.44 13.748 20.3156 13.4867 20.0667C13.2378 19.8053 13.1133 19.4942 13.1133 19.1333C13.1133 18.76 13.2378 18.4489 13.4867 18.2C13.748 17.9511 14.0591 17.8267 14.42 17.8267C14.7809 17.8267 15.0858 17.9511 15.3347 18.2C15.596 18.4489 15.7267 18.76 15.7267 19.1333C15.7267 19.4942 15.596 19.8053 15.3347 20.0667C15.0858 20.3156 14.7809 20.44 14.42 20.44Z" fill="#52A7F9")

			form-add-personal(v-if='isAccountsLoadedAndReady && me && (!me.hasFilledPaymentAccounts() || forceEditProfile)')

			b-tabs(v-else nav-class='tabs-filled tabs-filled-transparent' v-model='tab' no-fade)
				b-tab(:title="$t('finance.accounts2')")
					.d-flex.justify-content-between.flex-wrap(v-if='accountListFiltered && accountListFiltered.length > 0 && isAccountsLoadedAndReady')
						card-bank-account(v-for='i in accountListFiltered'  :key='i.id + i.total.amount' :item='i' @openModalAddCard='openModalAddCard(i)' @openModalRemove='openModalRemove(i)' @openModalRemoveAccount='openModalRemoveAccount(i)' @openWithdrawCreated="openWithdrawCreated")
						account-transfer(v-if='canTransferToOOO')

				b-tab(:title="$t('finance.income')" lazy)
					.finance__wrap(v-if="hasIncomeItems")
						.d-flex.align-items-center.mb-3
							my-input-search.mr-2.finance__search(
								v-model="filters[1].find.value"
								:placeholder="$t('dashboards.search')"
							)
							pw-btn-icon.mr-2(:used="usedIncome" :icon="'fontello-icon-setting-4'"  @click="$bvModal.show('filters-income')")
							pw-btn-icon(@click="exportPayments" :disabled="total1 == 0" :icon="'fontello-icon-download-file'")
							table-per-page.ml-auto(v-if="!isMobile && total1" @onChange="selectPageSize" :from="from1" :total="total1")

						table-income.vuetable(
							:perPage='filters[1].itemsPerPage.value'
							:filters='filters[1]'
							:hideSearch="true"
							:hidePerPage="true"
							@onChangeCurrentPage='changeCurrentPage'
							@onGetItems='onLoad'
						)

					.d-flex.flex-column.mx-auto.py-5.mt-3(v-else)
						h1.mb-3.text-center 😢
						span.b1.text-center {{ $t('errors.emptyIncome') }}

					modal-general(:id="'filters-income'" :contentClass="'content-filters'" :showCloseIcon="false" :title="$t('button.filters')")
						finance-filters(@onApply="applyFinance" :projectsList="projectList" @click="$bvModal.hide('filters-income')" type='income')

				b-tab(:title="$t('finance.payments')" lazy)
					.finance__wrap(v-if="hasWithdrawalItems")
						.d-flex.align-items-center.mb-3
							my-input-search.mr-2.finance__search(
								v-model="filters[2].find.value"
								:placeholder="$t('dashboards.search')"
							)
							pw-btn-icon.mr-2(:used="usedWithdrawals" :icon="'fontello-icon-setting-4'"  @click="$bvModal.show('filters-withdrawals')")
							pw-btn-icon(@click="exportWithdrawals" :disabled="total2 == 0" :icon="'fontello-icon-download-file'")
							table-per-page.ml-auto(v-if="!isMobile && total2" @onChange="selectPageSize" :from="from2" :total="total2")

						table-payments.vuetable(
							:perPage='filters[2].itemsPerPage.value'
							:filters='filters[2]'
							:hideSearch="true"
							:hidePerPage="true"
							@onChangeCurrentPage='changeCurrentPageWithdrawal'
							@onGetItems='onLoad'
						)

					.d-flex.flex-column.mx-auto.py-5.mt-3(v-else)
						h1.mb-3.text-center 😢
						span.b1.text-center {{ $t('errors.emptyPayments') }}

					modal-general(:id="'filters-withdrawals'" :contentClass="'content-filters'" :showCloseIcon="false" :title="$t('button.filters')")
						finance-filters(@onApply="applyWithdrawals" @click="$bvModal.hide('filters-withdrawals')" type='withdrawals')

		modal-withdraw(@openWithdrawCreated="openWithdrawCreated")
		modal-withdraw-schedule
		modal-add-bank-card(:item='selectedCardForAddCard' @waitingCard="openModalWaitingCard" @errorCard="openModalCardError")
		modal-error-add-bank-card(:reason="reason" :code="code")
		modal-remove-bank-card(:item='selectedCardForRemove' @cardDelete="afterDeleteCard")
		modal-remove-account(:account='selectedAccountForRemove')
		modal-edit-account-company
		modal-inactive-shop
		modal-waiting-card
		modal-withdrawal-success
		modal-wizard-success
	.loading(v-else)
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardBankAccount from '@/components/Cards/BankAccount';
import CardBankCard from '@/components/Cards/BankCard';
import CardPersonal from '@/components/Cards/Personal';
import HowItWorksAlert from '@/components/Cards/HowItWorksAlert';
import Withdraw from '@/components/Modals/Withdraw';
import WithdrawSchedule from '@/components/Modals/WithdrawSchedule';
import AddBankCard from '@/components/Modals/AddBankCard';
import ErrorAddBankCard from '@/components/Modals/ErrorAddBankCard';
import RemoveBankCard from '@/components/Modals/RemoveBankCard';
import RemoveAccount from '@/components/Modals/RemoveAccount';
import ModalEditAccountCompany from '@/components/Modals/EditAccount';
import ModalInActiveShop from '@/components/Modals/ModalInActiveShop';
import ModalWaitingCard from '@/components/Modals/ModalWaitingCard';
import ModalWithdrawalSuccess from '@/components/Modals/ModalWithdrawalSuccess';
import WizardSuccess from '@/components/Modals/ModalWizardSuccess';
import PwDropdownAccounts from '@/components/Form/PwDropdownAccounts';
import PwDropdownProjects from '@/components/Form/PwDropdownProjects';
import TablePerPage from '@/components/Tables/TablePerPage';
import PwBtnReset from '@/components/Buttons/PwBtnReset';
import PwBtnMobileFilters from '@/components/Buttons/PwBtnMobileFilters';
import PwSelect2 from '@/components/Form/PwSelect2';
import AddPersonal from '@/components/Form/AddPersonal';

import TableIncome from '@/components/Tables/FinanceIncome';
import TablePayments from '@/components/Tables/FinanceWithdraw';
import MyInputSearch from '@/components/UI/MyInputSearch';
import PwBtnIcon from '@/components/Buttons/PwBtnIcon';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import FinanceFilters from '@/components/Form/FinanceFilters';
import AccountTransfer from '@/components/Common/AccountTransfer';

import moment from 'moment-timezone';
import { tablePerPage, tablePageSizesExtended } from '@/constants/config';
import { localePicker, localePickerRanges } from '@/constants/config';
import { STATUS_REMOVED, STATUS_ACTIVE } from '@/models/model';
import { STORAGE } from '@/api/storage';
import { bus } from '@/main.js';

export default {
    components: {
        CardBankAccount,
        CardBankCard,
        CardPersonal,
        'modal-withdraw': Withdraw,
        'modal-add-bank-card': AddBankCard,
        'modal-error-add-bank-card': ErrorAddBankCard,
        'modal-remove-bank-card': RemoveBankCard,
        'modal-remove-account': RemoveAccount,
        'modal-withdraw-schedule': WithdrawSchedule,
        'modal-inactive-shop': ModalInActiveShop,
        'modal-wizard-success': WizardSuccess,
        'form-add-personal': AddPersonal,
        ModalEditAccountCompany,
        ModalWaitingCard,
        ModalWithdrawalSuccess,
        HowItWorksAlert,
        PwDropdownAccounts,
        PwDropdownProjects,
        PwBtnReset,
        PwBtnMobileFilters,
        PwSelect2,
        TablePerPage,

        MyInputSearch,
        PwBtnIcon,
        ModalGeneral,
        TableIncome,
        TablePayments,
        FinanceFilters,
        AccountTransfer,
    },
    metaInfo() {
        return { title: bus.$t('h1.finance') };
    },
    data: () => ({
        tick: 0,
        usedIncome: false,
        usedWithdrawals: false,
        notJustOpenedPage: true,

        filters: [
            {},
            {
                project: { value: null },
                find: { value: null },
                account: { value: null },
                status: { value: null },
                'createdAt[after]': { value: null },
                'createdAt[before]': { value: null },
                tz: { value: moment.tz.guess() },
                itemsPerPage: { value: tablePerPage },
            },
            {
                account: { value: null },
                find: { value: null },
                status: { value: null },
                'createdAt[after]': { value: null },
                'createdAt[before]': { value: null },
                tz: { value: moment.tz.guess() },
                itemsPerPage: { value: tablePerPage },
            },
        ],
        date: {
            startDate: null,
            endDate: new Date(),
            period: null,
        },
        date1: {
            startDate: null,
            endDate: new Date(),
            period: null,
        },
        defaultDate: {
            startDate: null,
            endDate: null,
            period: null,
        },
        defaultDate1: {
            startDate: null,
            endDate: null,
            period: null,
        },
        pageSizes: tablePageSizesExtended,
        currentPage: { value: 1 },
        currentPageWithdrawal: { value: 1 },
        selectedAccountForRemove: null,
        selectedCardForWithdrawal: null,
        selectedCardForRemove: null,
        selectedCardForAddCard: null,
        tab: 0,
        errors: null,
        forAll: false,
        isAccountsLoadedAndReady: false,
        locale:
            STORAGE.getLang() && localePicker.filter(x => x.id === STORAGE.getLang()).length > 0
                ? localePicker.find(x => x.id === STORAGE.getLang())
                : localePicker[0],
        ranges:
            STORAGE.getLang() && localePicker.findIndex(x => x.id === STORAGE.getLang()) != -1
                ? localePickerRanges[localePicker.findIndex(x => x.id === STORAGE.getLang())]
                : localePickerRanges[0],
        getProjectsOnce: false,
        forceEditProfile: false,
        from1: 0,
        from2: 0,
        reason: '',
        code: '',
    }),
    computed: {
        ...mapGetters({
            accountList: 'finance/accounts',
            accountListActiveRemoved: 'finance/accountsActiveRemoved',
            projectList: 'project/items',
            withdrawals: 'finance/withdrawals',
            total1: 'finance/incomeTotal',
            total2: 'finance/withdrawalsTotal',
            projectOpened: 'project/opened',
        }),
        canTransferToOOO() {
            let i = _.find(this.accountListFiltered, a => {
                if ((a.isOOO() || a.isIP()) && a.hasRequisite()) return a;
            });

            return !i;
        },
        accountListFiltered() {
            return _.sortBy(this.accountListActiveRemoved, a => {
                return a.isRUB() ? 0 : 1;
            });
        },
        to1() {
            let max = this.currentPage.value * this.filters[1].itemsPerPage.value;
            return max < this.total1 ? max : this.total1;
        },
        to2() {
            let max = this.currentPageWithdrawal.value * this.filters[2].itemsPerPage.value;
            return max < this.total2 ? max : this.total2;
        },
        hasIncomeItems() {
            if (this.isPendingIncome || this.notJustOpenedPage) return true;

            if (!this.hasFiltersIncome && this.total1 == 0) return false;

            return true;
        },
        hasWithdrawalItems() {
            if (this.isPendingWithdrawal || this.notJustOpenedPage) return true;

            if (!this.hasFiltersWithdraw && this.total2 == 0) return false;

            return true;
        },
        hasFiltersIncome() {
            if (this.filters[1].find.value || this.usedIncome) return true;

            return false;
        },
        hasFiltersWithdraw() {
            if (this.filters[2].find.value || this.usedWithdrawals) return true;

            return false;
        },
    },
    async created() {
        this.goAwayIfNeed();

        _.each(this.filters, f => {
            if (f.account) f.account.title = this.$t('finance.bankAccount');
            if (f.project) f.project.title = this.$t('h1.project');
        });

        this.$store.commit('modals/financeFilters', { type: 'income' });
        this.$store.commit('modals/financeFilters', { type: 'withdrawals' });

        await this.getAccounts({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 });
        await this.getAccountsFinanceWrap();

        if (this.$route && this.$route.query && (this.$route.query.editProfile || this.$route.query.editAccount))
            this.forceEditProfile = true;
        if (this.$route.query && this.$route.query.type == 'payments') {
            this.tab = 1;
            this.filters[this.tab].find.value = this.$route.query.search;
        }
        document.getElementsByTagName('main')[0].classList.add('main-xs-top');
    },
    mounted() {
        this.$root.$on('bv::dropdown::show', bvEvent => {
            bus.$emit('hideDateRangePicker');
        });
    },
    methods: {
        ...mapActions({
            getAccounts: 'finance/accounts',
            getAccountsFinance: 'users/finance',
            getProjects: 'project/items',
            editMe: 'auth/meEdit',
        }),
        async afterDeleteCard() {
            await this.getAccounts({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 });
            await this.getAccountsFinance({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 }).then(
                list => {
                    _.each(this.accountList, a => {
                        _.each(list, l => {
                            if (a.id == l.id) {
                                a.setFinance(l);
                            }
                        });
                    });

                    this.$store.commit('finance/setAccountsActiveRemoved', this.accountList);

                    this.me.setAccounts(this.accountListActiveRemoved);
                }
            );
        },
        exportPayments() {
            bus.$emit('tableFinanceIncomeExport');
        },
        async getAccountsFinanceWrap() {
            await this.getAccountsFinance({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 }).then(
                list => {
                    _.each(this.accountList, a => {
                        _.each(list, l => {
                            if (a.id == l.id) {
                                a.setFinance(l);
                            }
                        });
                    });

                    this.$store.commit('finance/setAccountsActiveRemoved', this.accountList);

                    this.me.setAccounts(this.accountListActiveRemoved);

                    if (!this.me.hasFilledAccount() && this.me.hasActiveIndividualAccount())
                        this.$bvModal.show('modalNeedToFillProfile');

                    this.isAccountsLoadedAndReady = true;
                }
            );
        },
        exportWithdrawals() {
            bus.$emit('tableFinanceExport');
        },
        applyFinance(v) {
            this.usedIncome = v.used;
            this.filters[1].project = { value: v.projects };
            this.filters[1].account = { value: v.accountList };
            this.filters[1].status = { value: v.status };
            this.filters[1]['createdAt[after]'] = {
                value:
                    v.incomeDate && v.incomeDate.start
                        ? moment(v.incomeDate.start).format('YYYY-MM-DDT00:00:00')
                        : null,
            };
            this.filters[1]['createdAt[before]'] = {
                value: v.incomeDate && v.incomeDate.end ? moment(v.incomeDate.end).format('YYYY-MM-DDT23:59:59') : null,
            };
            this.filters[1].page = { value: 1 };
            this.$nextTick(() => {
                bus.$emit('tableFinanceIncomeRefresh');
            });
        },
        applyWithdrawals(v) {
            this.usedWithdrawals = v.used;
            this.filters[2].account = { value: v.accountList };
            this.filters[2].status = { value: v.status };
            this.filters[2]['createdAt[after]'] = {
                value:
                    v.incomeDate && v.incomeDate.start
                        ? moment(v.incomeDate.start).format('YYYY-MM-DDT00:00:00')
                        : null,
            };
            this.filters[2]['createdAt[before]'] = {
                value: v.incomeDate && v.incomeDate.end ? moment(v.incomeDate.end).format('YYYY-MM-DDT23:59:59') : null,
            };
            this.filters[2].page = { value: 1 };
            this.$nextTick(() => {
                bus.$emit('tableFinanceWithdrawalRefresh');
            });
        },
        onLoad(v) {
            this.$bvModal.hide(`filters-income`);
            this.$bvModal.hide(`filters-withdrawals`);
            this.notJustOpenedPage = false;
            if (v.type == 'payments') return (this.from1 = v.amount);
            this.from2 = v.amount;
        },
        selectDate(val) {
            this.filters[this.tab]['createdAt[after]'] = {
                value: val.startDate == null ? null : moment(val.startDate).format('YYYY-MM-DDT00:00:00'),
            };
            this.filters[this.tab]['createdAt[before]'] = {
                value: val.endDate == null ? null : moment(val.endDate).format('YYYY-MM-DDT23:59:59'),
            };
        },
        clearFilters() {
            this.filters[this.tab].find = { value: null };
            this.filters[this.tab].account = { value: null, title: this.$t('finance.bankAccount') };

            if (this.tab == 1) {
                this.filters[this.tab].project = { value: null, title: this.$t('h1.project') };
                this.date = {
                    startDate: null,
                    endDate: this.defaultDate.endDate,
                };
                this.selectDate(this.date);
                return;
            }

            this.date1 = {
                startDate: null,
                endDate: this.defaultDate1.endDate,
            };

            this.selectDate(this.date1);
        },
        changeCurrentPage(v) {
            this.currentPage.value = v;
        },
        changeCurrentPageWithdrawal(v) {
            this.currentPageWithdrawal.value = v;
        },
        selectAccount(p) {
            this.filters[this.tab].account.value = _.values(_.filter(p, i => !_.isNil(i)));
            this.filters[this.tab].page = { value: 1 };
        },
        selectProject(p) {
            this.filters[this.tab].project.value = p;
            this.filters[this.tab].page = { value: 1 };
        },
        selectPageSize(perPage) {
            this.filters[this.tab].itemsPerPage.value = perPage;
        },
        openModalRemove(item) {
            this.selectedCardForRemove = null;
            this.forAll = false;

            this.$nextTick(() => {
                this.selectedCardForRemove = item;
            });
        },
        openModalRemoveForAllAccounts(item) {
            this.selectedCardForRemove = null;
            this.forAll = false;

            this.$nextTick(() => {
                this.selectedCardForRemove = { card: item, canRemove: true };
                this.forAll = true;
            });
        },
        openModalRemoveAccount(item) {
            this.selectedAccountForRemove = null;

            this.$nextTick(() => {
                this.selectedAccountForRemove = item;
            });
        },
        openModalAddCard(item) {
            this.selectedCardForAddCard = null;

            this.$nextTick(() => {
                this.selectedCardForAddCard = item;
            });
        },
        openModalComments(item) {
            this.$store.commit('modals/setModalComments', null);
            this.$nextTick(() => {
                this.$store.commit('modals/setModalComments', item);
            });
        },
        openModalWaitingCard() {
            this.$bvModal.show('modalWaitingCard');
        },
        openModalCardError() {
            this.reason = STORAGE.getCardError();
            this.code = STORAGE.getCardErrorCode() ? STORAGE.getCardErrorCode() : '';
            this.$bvModal.hide('modalWaitingCard');
            this.$bvModal.show('modalerroraddbankcard');
        },
        openModalAddAccount() {
            this.$store.commit('finance/setWizardAddAccountOpened', true);
        },
        openModalFilters(index) {
            this.$store.commit('project/setItemsShort', null);
            this.$store.commit('modals/setMobileFilters', null);

            let params = {
                accounts: _.cloneDeep(this.filters[this.tab].account.value),
                date: {
                    startDate: this.filters[this.tab]['createdAt[after]'].value,
                    endDate: this.filters[this.tab]['createdAt[before]'].value,
                },
            };

            if (this.tab == 1) {
                params.projects = _.cloneDeep(this.filters[this.tab].project.value);
                params.defaultDate = _.cloneDeep(this.defaultDate);
            }

            if (this.tab == 2) {
                params.defaultDate = _.cloneDeep(this.defaultDate1);
            }

            this.$nextTick(() => this.$store.commit('modals/setMobileFilters', params));
        },
        setCurrentWithdrawalsToAccounts() {
            _.each(
                _.filter(this.withdrawals, ww => ww.isRequested()),
                w => {
                    _.each(this.accountList, a => {
                        if (w.account.id == a.id) {
                            a.setCurrentWithdrawal(w);
                        }
                    });
                }
            );
        },
        updateList() {
            this.isAccountsLoadedAndReady = false;
            this.$nextTick(v => (this.isAccountsLoadedAndReady = true));
        },
        openWithdrawCreated() {
            this.getAccountsFinanceWrap();
            this.$bvModal.show(`withdraw-created`);
        },
        goAwayIfNeed() {
            if (!this.me) return;
            if (this.me.isMerchantPartner) return;
            if (this.me && !this.me.hasFilledAccount()) this.$router.push({ name: 'profile' });
        },

        showWizardSuccess() {
            if (
                STORAGE.getFinance() &&
                STORAGE.getFinance() != null &&
                this.projectOpened &&
                this.projectOpened.isActive()
            ) {
                this.$bvModal.show('modalwizardsuccess');
                STORAGE.setFinance(null);
                return;
            }
        },
    },
    watch: {
        me() {
            this.goAwayIfNeed();
        },
        tab(nv) {
            this.notJustOpenedPage = true;
            if (nv == 1 && !this.getProjectsOnce) {
                this.getProjects({ itemsPerPage: 5000 });
                this.getProjectsOnce = true;
            }
        },
        withdrawals() {
            if (this.accountListActiveRemoved && this.accountListActiveRemoved.length > 0) {
                this.setCurrentWithdrawalsToAccounts();
            }
        },

        accountListActiveRemoved() {
            if (this.accountListActiveRemoved && this.accountListActiveRemoved.length > 0) {
                this.setCurrentWithdrawalsToAccounts();
            }
        },
        $route(v) {
            if (v && v.query && (v.query.editProfile || v.query.editAccount)) return (this.forceEditProfile = true);
            this.forceEditProfile = false;
        },
        projectOpened(n, o) {
            if (this.tick == 0) {
                this.showWizardSuccess();
                this.tick++;
            }
        },
        filters: {
            handler(n) {
                this.notJustOpenedPage = true;
            },
            deep: true,
        },
    },
    destroyed() {
        let main = document.getElementsByTagName('main');

        if (main && main[0]) main[0].classList.remove('main-xs-top');
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.finance {
    height: 100%;

    &__wrap {
        padding: 20px;
        height: max-content;
        background-color: var(--grayscale-dark-white-back);
        border-radius: var(--border-radius-rounded);
        box-shadow: var(--box-shadow);

        @include respond-below(md) {
            padding: 16px;
            border-radius: 0;
            margin: 0 -12px;
            box-shadow: var(--box-shadow-mobile);
        }
    }

    &__search {
        max-width: 180px;

        @include respond-below(sm) {
            max-width: 100%;
        }
    }

    &__withdraw-img {
        display: block;
        width: 192px;
        height: 192px;
    }
}
</style>
